import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import '../scss/_tooltip.scss';

const Positions = {
  LEFT: 'tooltip_left',
  TOP: 'tooltip_top',
  RIGHT: 'tooltip_right',
  BOTTOM: 'tooltip_bottom',
  'BOTTOM-LEFT': 'tooltip_bottom-left',
  'BOTTOM-RIGHT': 'tooltip_bottom-right',
};

export class Tooltip extends PureComponent {
  constructor(props) {
    super(props);
    this.labelInnerWidth = props.labelMaxWidth ? (props.labelMaxWidth - 20) : 480; // 2x 10px horizontal paddings
    this.state = {
      isVisible: false,
      isShowing: false, // visible with animation duration
      position: Positions[props.position.toUpperCase()],
      labelInnerMaxWidth: this.labelInnerWidth,
    };

    this.timer = null;
  }

  tooltipLabel = createRef();

  componentDidMount() {
    this.onFontLoaded();
  }

  componentDidUpdate() {
    if (!this.state.isLabelMaxWidthSet) {
      this.setLabelMaxWidth();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onFontLoaded = () => {
    const font = new FontFaceObserver('Lato Medium');

    font.load().then(() => {
      this.setStyleToInitial();
    });
  }

  setStyleToInitial = () => {
    this.setState({
      labelInnerMaxWidth: this.labelInnerWidth,
      isLabelMaxWidthSet: false,
    });
  }

  setLabelMaxWidth = () => {
    if (this.tooltipLabelInner) {
      let contentWidth = Math.ceil(this.tooltipLabelInner.getBoundingClientRect().width);
      contentWidth = contentWidth <= this.labelInnerWidth ? contentWidth : this.labelInnerWidth;
      this.setState({
        labelInnerMaxWidth: contentWidth,
        isLabelMaxWidthSet: true,
      });
    }
  }

  getPosition() {
    if (this.props.auto) {
      clearTimeout(this.process);
      const { isShowing, position: current } = this.state;
      if (isShowing) {
        return current;
      }

      let position = Positions.TOP;

      const coord = this.tooltipLabel.current.getBoundingClientRect();
      const { x, y, right } = coord;

      if (y < 0) {
        position = Positions.BOTTOM;
      }

      if (x < 0) {
        position = Positions.RIGHT;
      }

      if (right > window.innerWidth) {
        position = Positions.LEFT;
      }

      return position;
    } else {
      return this.state.position;
    }
  }

  handleMouseEnter = () => {
    this.setStyleToInitial();
    const position = this.getPosition();
    this.timer = setTimeout(() => {
      this.setState({
        isVisible: true,
        isShowing: true,
        position,
      });
    }, this.props.delayTime);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timer);
    this.setState({
      isVisible: false,
    }, () => {
      this.process = setTimeout(() => {
        this.setState({
          position: this.props.auto ? Positions.TOP : Positions[this.props.position.toUpperCase()],
          isShowing: false,
        });
      }, 200); // Animation duration
    });
  };

  render() {
    const {
      className = '',
      style,
      handleClick,
    } = this.props;

    const {
      isVisible,
      position,
      labelInnerMaxWidth,
    } = this.state;

    const visible = isVisible ? 'tooltip_visible' : 'tooltip_hidden';
    const tooltipClassName = `tooltip ${position} ${visible} ${className}`;

    return (
      <div
        className={tooltipClassName}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={style}
        onClick={handleClick}
      >
        <span ref={this.tooltipLabel} className="tooltip__label">
          <span className="tooltip__label-outer" style={{width: `${this.labelInnerWidth}px`, maxWidth: `${labelInnerMaxWidth}px`}}>
            <span ref={el => { this.tooltipLabelInner = el; }} className="tooltip__label-inner">
              {this.props.label}
            </span>
          </span>
        </span>
        {this.props.children}
      </div>
    );
  }
}

Tooltip.defaultProps = {
  delayTime: 500,
  position: 'top',
  auto: true,
};

Tooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  delayTime: PropTypes.number,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'bottom-left', 'bottom-right', 'left']),
  auto: PropTypes.bool,
  labelMaxWidth: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};
