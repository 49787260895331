import React from 'react';
import { ClockNumber } from './ClockNumber';
import { getHoursValue } from './utils';

export const getHourNumbers = (
  {
    ampm,
    date,
    size,
  },
) => {
  const currentHours = getHoursValue(date);

  const hourNumbers = [];
  const startHour = ampm ? 1 : 0;
  const endHour = ampm ? 12 : 23;

  const isSelected = (hour) => {
    if (ampm) {
      if (hour === 12) {
        return currentHours === 12 || currentHours === 0;
      }

      return currentHours === hour || currentHours - 12 === hour;
    }

    return currentHours === hour;
  };

  for (let hour = startHour; hour <= endHour; hour += 1) {
    let label = hour.toString();

    if (hour === 0) {
      label = '00';
    }

    const props = {
      index: hour,
      label: label,
      selected: isSelected(hour),
      size,
    };

    hourNumbers.push(<ClockNumber key={hour} {...props} />);
  }

  return hourNumbers;
};

export const getMinutesNumbers = (
  {
    value,
    size,
  },
) => {
  return [
    <ClockNumber size={size} label={'00'} selected={value === 0} index={12} key={12}/>,
    <ClockNumber size={size} label={'05'} selected={value === 5} index={1} key={1}/>,
    <ClockNumber size={size} label={'10'} selected={value === 10} index={2} key={2}/>,
    <ClockNumber size={size} label={'15'} selected={value === 15} index={3} key={3}/>,
    <ClockNumber size={size} label={'20'} selected={value === 20} index={4} key={4}/>,
    <ClockNumber size={size} label={'25'} selected={value === 25} index={5} key={5}/>,
    <ClockNumber size={size} label={'30'} selected={value === 30} index={6} key={6}/>,
    <ClockNumber size={size} label={'35'} selected={value === 35} index={7} key={7}/>,
    <ClockNumber size={size} label={'40'} selected={value === 40} index={8} key={8}/>,
    <ClockNumber size={size} label={'45'} selected={value === 45} index={9} key={9}/>,
    <ClockNumber size={size} label={'50'} selected={value === 50} index={10} key={10}/>,
    <ClockNumber size={size} label={'55'} selected={value === 55} index={11} key={11}/>,
  ];
};
