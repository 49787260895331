import React from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import '../scss/_route-transition.scss';

class RouteContainerComponent extends React.Component {
  oldPath = '';

  componentDidMount() {
    this.oldPath = this.props.location.pathname;
  }

  getTransitionDirectionClassName() {
    const current = this.props.location.pathname;
    if(!this.oldPath) {
      if(current) {
        this.oldPath = current;
      }
    } else if(this.oldPath !== current) {
      let oldPathSlashesCount = this.oldPath !== "/" ? (this.oldPath.match(/\//g) || []).length : 0;
      let newPathSlashesCount = current !== "/" ? (current.match(/\//g) || []).length : 0;

      this.oldPath = current;

      if(oldPathSlashesCount > newPathSlashesCount) {
        return "_reverse";
      }
    }

    return "";
  }

  render() {
    const {
      location,
      children,
    } = this.props;
    return (
      <TransitionGroup className={`transition-container ${this.getTransitionDirectionClassName()}`}>
        <CSSTransition
          key={location.key}
          timeout={1000}
          classNames='fade'
        >
          {children(location)}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export const RouteWrapper = ({className = '', children}) => {
  return (
    <div className={`transition-container__item ${className}`}>
      {children}
    </div>
  );
};

export const RouteContainer = withRouter(RouteContainerComponent);
