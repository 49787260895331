import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import FontFaceObserver from 'fontfaceobserver';

import styles from './tableHeaderTooltip.module.scss';

export class TableHeaderTooltip extends Component {
  constructor(props) {
    super();
    this.labelInnerWidth = props.labelMaxWidth ? (props.labelMaxWidth - 20) : 480; // 2x 10px horizontal paddings
    this.state = {
      position: 'bottom',
      labelInnerMaxWidth: this.labelInnerWidth,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.isTooltipChanged = !isEqual(nextProps.tooltip, this.props.tooltip);
    if (this.isTooltipChanged) {
      this.isTooltipWidthSet = false;
    } else if (!isEqual(nextState.labelInnerMaxWidth, this.state.labelInnerMaxWidth)) {
      this.isTooltipWidthSet = true;
    }
    this.isScrollLeftChanged = !isEqual(nextProps.scrollLeft, this.props.scrollLeft);

    return !(
      isEqual(nextProps.tooltip, this.props.tooltip) &&
      isEqual(nextProps.cellsWidths, this.props.cellsWidths) &&
      isEqual(nextProps.scrollLeft, this.props.scrollLeft) &&
      isEqual(nextProps.freezeColumns, this.props.freezeColumns) &&
      isEqual(nextState.position, this.state.position) &&
      isEqual(nextState.labelInnerMaxWidth, this.state.labelInnerMaxWidth)
    );
  }

  componentDidMount() {
    this.onFontLoaded();
  }

  componentDidUpdate() {
    this.setLabelMaxWidth(this.isTooltipChanged);
    if (this.isTooltipWidthSet) {
      this.getPosition(this.isScrollLeftChanged);
    }
  }

  onFontLoaded = () => {
    const font = new FontFaceObserver('Lato Medium');

    font
      .load()
      .then(() => this.setLabelMaxWidth())
      .then(() => this.getPosition());
  }

  setLabelMaxWidth = (isTooltipChanged) => {
    if (isTooltipChanged === undefined || isTooltipChanged === true) {
      let contentWidth = Math.ceil(this.tooltipLabelInner.getBoundingClientRect().width);
      contentWidth = contentWidth <= this.labelInnerWidth ? contentWidth : this.labelInnerWidth;
      this.setState({labelInnerMaxWidth: contentWidth, position: 'bottom'});
    }
  }

  getPosition(isScrollLeftChanged) {
    let position = isScrollLeftChanged ? 'bottom' : this.state.position;

    const coord = this.tooltipLabel.getBoundingClientRect();
    const { x, right } = coord;

    if (x < 0) {
      position = 'right';
    }

    if (right > window.innerWidth) {
      position = 'left';
    }

    if (position !== this.state.position) {
      this.setState({position});
    }
  }

  render() {
    const {
      tooltip,
      cellsWidths = [],
      scrollLeft = 0,
      freezeColumns = 0,
      className = '',
    } = this.props;

    let {
      top,
    } = this.props;

    const {
      position,
      labelInnerMaxWidth,
    } = this.state;

    let left = '';
    let right = 'auto';

    let positionClassName = '';

    // sum of cells widths before active one
    const sumOfCellsWidthsBeforeActive = cellsWidths.slice(0, tooltip.i).reduce((a, b) => a + b, 0);
    // scroll correction, assuming frozen columns
    const scrollCorrection = (tooltip.i >= freezeColumns ? scrollLeft : 0);

    switch (position) {
      case 'bottom':
        left = (tooltip.i ? sumOfCellsWidthsBeforeActive : 0) +
          cellsWidths[tooltip.i] / 2 - // half a width of active cell
          scrollCorrection +
          'px';
        break;
      case 'left':
        top = top / 2;
        left = sumOfCellsWidthsBeforeActive - scrollCorrection + 'px';
        positionClassName = styles._left;
        break;
      case 'right':
        top = top / 2;
        left = (tooltip.i ? sumOfCellsWidthsBeforeActive : 0) + cellsWidths[tooltip.i] - scrollCorrection + 'px';
        positionClassName = styles._right;
        break;
    }

    const style = {
      top: `${top}px`,
      left: left,
      right: right,
    };

    return (
      <div
        ref={el => { this.tooltipLabel = el; }}
        className={`${styles.tooltip} td-tooltip ${className} ${positionClassName} tooltip__label`}
        style={style}
      >
        <span
          className="tooltip__label-outer"
          style={{
            width: `${this.labelInnerWidth}px`,
            maxWidth: `${this.isTooltipChanged ? this.labelInnerWidth : labelInnerMaxWidth}px`,
          }}
        >
          <span ref={el => { this.tooltipLabelInner = el; }} className="tooltip__label-inner">
            {tooltip.text}
          </span>
        </span>
      </div>
    );
  }
}

TableHeaderTooltip.propTypes = {
  tooltip: PropTypes.shape({
    i: PropTypes.number,
    text: PropTypes.string,
  }),
  cellsWidths: PropTypes.arrayOf(
    PropTypes.number,
  ),
  top: PropTypes.number,
  scrollLeft: PropTypes.number,
  freezeColumns: PropTypes.number,
  className: PropTypes.string,
  labelMaxWidth: PropTypes.number,
};
