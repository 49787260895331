export const isEqual = (props, first, second) => {
  let equal = true;
  for(let ii = 0; ii < props.length; ii++) {
    const property = props[ii];
    if (first[property] !== second[property]) {
      equal = false;
      return equal;
    }
  }
  return equal;
};