import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_buttons-group.scss';
import { isEqual } from '../utils/compare';

export class ButtonsGroup extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual([
      'items',
      'value',
      'className',
      'size',
    ], this.props, nextProps);
  }

  static defaultProps = {
    onChange: () => {},
    className: '',
  };

  render() {
    const {
      items,
      value,
      onChange,
      className,
      size,
    } = this.props;

    const isCheckboxes = Array.isArray(value);

    return (
      <div className={`btn-group ${size ? `_${size}` : ''} ${className}`}>
        {items.map((item, index) => {
          let active = undefined;

          if(isCheckboxes) {
              active = value.indexOf(item.value) !== -1 ? '_active' : '';
          } else {
            active = item.value === value ? '_active' : '';
		  }

          return (
              <button
                  onClick={() => onChange(item.value)}
                  key={index}
                  className={`btn-group__item ${active}`}
              >
				  {item.title}
              </button>
          );
        })}
      </div>
    );
  }
}

ButtonsGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
