export function getFreezeColumnObject(first) {
  return {
    ref: (el) => {
      if (el) {
        if (first) {
          const elLeft = 0;
          el.style.left = `${elLeft}px`;
          el.dataset.offsetLeft = el.getBoundingClientRect().width + elLeft + getElementMargin(el);
        } else {
          const sibling = el.previousSibling;
          const offsetLeft = sibling.dataset.offsetLeft;
          el.style.left = `${offsetLeft}px`;
          el.dataset.offsetLeft = el.getBoundingClientRect().width + +offsetLeft + getElementMargin(el);
        }
      }
    },
  };
}

export function getElementMargin(el) {
  const style = window.getComputedStyle(el);
  return Number.parseFloat(style.marginLeft) + Number.parseFloat(style.marginRight);
}

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};
