import React from 'react';
import styles from './clockNumber/ClockNumber.module.scss';
import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';

const positions = {
  0: [0, 40],
  1: [55, 19.6],
  2: [94.4, 59.5],
  3: [109, 114],
  4: [94.4, 168.5],
  5: [54.5, 208.4],
  6: [0, 223],
  7: [-54.5, 208.4],
  8: [-94.4, 168.5],
  9: [-109, 114],
  10: [-94.4, 59.5],
  11: [-54.5, 19.6],
  12: [0, 5],
  13: [36.9, 49.9],
  14: [64, 77],
  15: [74, 114],
  16: [64, 151],
  17: [37, 178],
  18: [0, 188],
  19: [-37, 178],
  20: [-64, 151],
  21: [-74, 114],
  22: [-64, 77],
  23: [-37, 50],
};

const memoizeTransform = memoize((index) => {
  const position = positions[index];

  return {
    transform: `translate(${position[0]}px, ${position[1]}px`,
  };
});

export const ClockNumber = ({ selected, label, index, size }) => {
  const transformStyle = memoizeTransform(index);

  return (
    <span
      className={`${styles.clockNumber} ${size ? styles[`clockNumber-${size}`] : ''} ${selected ? styles.clockNumberSelected : ''}`}
      style={transformStyle}
    >
      {label}
    </span>
  );
};

ClockNumber.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  index: PropTypes.number,
  size: PropTypes.oneOf(['sm', '']),
};
