import React from 'react';
import PropTypes from 'prop-types';
import { getHourNumbers, getMinutesNumbers } from './ClockNumbers';
import { Clock } from './Clock';
import {
  ClockTypes,
  getHoursValue,
  getMinutesValue,
  getSecondsValue,
  setHoursValue,
  setMinutesValue,
  setSecondsValue,
} from './utils';
import memoize from 'lodash/memoize';
import { convertToMeridiem, getMeridiem } from './time-utils';

export class ClockView extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['hours', 'minutes', 'seconds']).isRequired,
    date: PropTypes.number.isRequired,
    ampm: PropTypes.bool,
    onHourChange: PropTypes.func,
    onMinutesChange: PropTypes.func,
    onSecondsChange: PropTypes.func,
    minutesStep: PropTypes.number,
    size: PropTypes.oneOf(['sm', '']),
  };

  render() {
    const {
      type,
      ampm,
      date,
      onHourChange,
      onMinutesChange,
      onSecondsChange,
      minutesStep,
      size,
    } = this.props;
    const viewProps = memoize(() => {
      switch (type) {
        case ClockTypes.HOURS: {
          return {
            value: getHoursValue(date),
            children: getHourNumbers({ ampm, date, size }),
            onChange: (value, isFinish) => {
              const currentMeridiem = getMeridiem(date);
              const updatedTimeWithMeridiem = convertToMeridiem(
                setHoursValue(date, value),
                currentMeridiem,
                Boolean(ampm),
              );

              onHourChange(updatedTimeWithMeridiem, isFinish);
            },
          };
        }

        case ClockTypes.MINUTES: {
          const minutesValue = getMinutesValue(date);
          return {
            value: minutesValue,
            children: getMinutesNumbers({ value: minutesValue, size }),
            onChange: (value, isFinish) => {
              const updatedTime = setMinutesValue(date, value);

              onMinutesChange(updatedTime, isFinish);
            },
          };
        }

        case ClockTypes.SECONDS: {
          const secondsValue = getSecondsValue(date);
          return {
            value: secondsValue,
            children: getMinutesNumbers({ value: secondsValue, size }),
            onChange: (value, isFinish) => {
              const updatedTime = setSecondsValue(date, value);

              onSecondsChange(updatedTime, isFinish);
            },
          };
        }

        default: {
          throw new Error(`You must provide the type for TimePicker: ${Object.values(ClockTypes).join(', ')}`);
        }
      }
    }, () => [ampm, date, onHourChange, onMinutesChange, onSecondsChange, type]);
    return (
      <Clock type={type} ampm={ampm} minutesStep={minutesStep} size={size} {...viewProps()} />
    );
  }
}
