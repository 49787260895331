import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_checkbox.scss';

import { ReactComponent as CbChecked } from './checkbox/check-box-24px.svg';
import { ReactComponent as CbBlank } from './checkbox/check-box-blank-24px.svg';
import { isEqual } from '../utils/compare';

export class Checkbox extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual([
      'label',
      'checked',
      'disabled',
      'className',
    ], this.props, nextProps);
  }

  static defaultProps = {
    onChange: () => {
    },
    checked: false,
    disabled: false,
  };

  render() {
    const {
      name,
      label,
      checked,
      disabled,
      onChange,
      className = '',
    } = this.props;

    const cb = checked ? <CbChecked/> : <CbBlank/>;
    return (
      <div className={`checkbox ${disabled ? 'checkbox_disabled' : ''} ${checked ? 'checkbox_checked' : ''} ${className}`}>

        <label className="checkbox__label" htmlFor={name}>
          <input
            type="checkbox"
            id={name}
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            className="checkbox__input"
          />
          {cb}
          {label &&
            <span className="checkbox__title">
              {label}
            </span>
          }
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
