import React from 'react';
import * as PropTypes from 'prop-types';
import '../scss/_logo.scss';

import { ReactComponent as IQMLogo } from './logo/iqm-logo.svg';

export const Logo = ({ className = '', type }) => {
  const classes = ['logo', className];
  if (type) {
    classes.push(`_${type}`);
  }
  return (
    <span className={classes.join(' ')}>
      <IQMLogo />
    </span>
  );
};

Logo.defaultProps = {};

Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['white', 'black']),
};
