import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_dialog.scss';
import { Modal } from './Modal';
import { Button } from './Button';

export class Dialog extends Component {

  componentDidMount() {
    if (this.props.handleEnterKeyEvent) {
      window.addEventListener('keydown', this.handleEnterKeyEvent);
    }
  }

  componentWillUnmount() {
    if (this.props.handleEnterKeyEvent) {
      window.removeEventListener('keydown', this.handleEnterKeyEvent);
    }
  }

  handleEnterKeyEvent = (e) => {
    if (!this.props.open || !(e.key === 'Enter')) {
      return;
    }

    this.props.handleEnterKeyEvent();
  }

  render() {
    const {
      open,
      className,
      children,
      onClickOutside,
      modalProps,
    } = this.props;

    if (!open) {
      return null;
    }

    return (
      <Modal open={open} onClickOutside={onClickOutside} modalProps={modalProps}>
        <div className={`dialog ${className ? className : ''}`}>
          {children}
        </div>
      </Modal>
    );
  }
}

export const DialogContent = ({ children }) => {
  return (
    <main className="dialog__content">
      {children}
    </main>
  );
};
export const DialogScrollableContent = ({ children }) => {
  return (
    <div className="dialog__content-scrollable">
      <DialogContent>{children}</DialogContent>
    </div>
  );
};
export const DialogHeader = ({ children }) => {
  return (
    <header className="dialog__header">
      {children}
    </header>
  )
};
export const DialogFooter = ({ children }) => {
  return (
    <footer className="dialog__footer">
      {children}
    </footer>
  )
};
export const DialogButtons = ({ buttons = [] }) => {
  return (
    <div className="dialog__buttons">
      {buttons.map((btn, i) => {
        return <Button key={i} onClick={btn.handler} className={`btn-square _conflower-blue ${btn.className || ""}`}>
          {btn.title}
        </Button>
      })}
    </div>
  )
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleEnterKeyEvent: PropTypes.func,
};
