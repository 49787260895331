import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_snackbar.scss';

export class Snackbar extends Component {
  static defaultProps = {
    autoHideDuration: 10000,
  };

  componentDidMount() {
    if (this.props.open) {
      this.setAutoHideTimer();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.setAutoHideTimer();
      } else {
        clearTimeout(this.timerAutoHide);
      }
    }
  }

  componentWillUnmount() {
    if (this.timerAutoHide) {
      clearTimeout(this.timerAutoHide);
    }
  }

  setAutoHideTimer(autoHideDuration) {
    const autoHideDurationBefore =
      autoHideDuration != null ? autoHideDuration : this.props.autoHideDuration;

    if (!this.props.onClose || autoHideDurationBefore == null) {
      return;
    }

    clearTimeout(this.timerAutoHide);
    this.timerAutoHide = setTimeout(() => {
      const autoHideDurationAfter =
        autoHideDuration != null ? autoHideDuration : this.props.autoHideDuration;
      if (!this.props.onClose || autoHideDurationAfter == null) {
        return;
      }

      this.props.onClose(null, 'timeout');
    }, autoHideDurationBefore);
  }

  render() {
    const {
      open,
      message,
      action,
      className = '',
    } = this.props;

    if (!open) {
      return null;
    }

    const classes = ['snackbar', className];

    return (
      <div className={classes.join(' ')}>
        <div className="snackbar__message">{message}</div>
        {action &&
          <div className="snackbar__action">{action}</div>
        }
      </div>
    );
  }
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  action: PropTypes.node,
  className: PropTypes.string,
};
