import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withClickOutside } from '../hoc/clickOutside';
import '../scss/_context-menu.scss';

class ContextMenuComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      position: {
        left: 0,
        top: 0,
      },
    };
  }

  handleClickOutside() {
    this.props.onClose();
  }

  componentDidMount() {
    this.calcPosition();
  }

  calcPosition = () => {
    const {
      clientX,
      clientY,
    } = this.props;

    const menuCoord = this.menu.getBoundingClientRect();
    const clientWidth = window.innerWidth;
    const clientHeight = window.innerHeight;
    const diffX = clientWidth - (menuCoord.width + clientX);
    const diffY = clientHeight - (menuCoord.height + clientY);

    this.setState({
      position: {
        left: `${diffX < 0 ? clientX + diffX : clientX}px`,
        top: `${diffY < 0 ? clientY + diffY : clientY}px`,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientX !== this.props.clientX || prevProps.clientY !== this.props.clientY) {
      this.calcPosition();
    }
  }

  render() {
    const {
      className = '',
      children,
    } = this.props;

    return (
      <div
        className={`context-menu ${className}`}
        ref={(el) => this.menu = el}
        style={this.state.position}
      >
        {children}
      </div>
    );
  }
}

export const ContextMenu = withClickOutside(ContextMenuComponent);

ContextMenuComponent.propTypes = {
  clientX: PropTypes.number.isRequired,
  clientY: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ContextMenu.propTypes = {
  clientX: PropTypes.number.isRequired,
  clientY: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};
