import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import '../scss/_table.scss';
import { TableContextMenu } from './table/TableContextMenu';
import { TableHeader } from './table/TableHeader';
import { tablePropTypes } from './table/PropTypes';
import { TableRow } from './table/TableRow';
import { SORT_DIRECTION } from './table/tableHelpers';
import { TableHeaderRow } from './table/TableHeaderRow';
import { TableHeaderTooltip } from './table/TableHeaderTooltip';
import FontFaceObserver from 'fontfaceobserver';

import styles from './table/table.module.scss';

export class Table extends Component {
  constructor(props) {
    super(props);
    this.tableUps = [];
    this.tableDowns = [];
    this.syncedElements = [];
    this.state = {
      paging: props.paging ? props.paging : null,
      sorting: props.sorting,
      contextMenu: null,
      fixScrollerAtBottom: true,
      freeze: {
        rows: props.freezeRows,
        columns: props.freezeColumns,
      },
    };

    if (!props.server) {
      this.state.data = props.data || [];
    }

    this.handleChangeSorting = this.handleChangeSorting.bind(this);
  }

  componentDidUpdate(prevProps) {
    const props = this.props;

    if (!props.server && (prevProps.data.length !== props.data.length || prevProps.data !== props.data)) {
      this.setState({
        data: props.data,
        paging: this.paging,
      });
    } else if (props.sorting !== prevProps.sorting) {
      this.setState({
        sorting: props.sorting
      });
    }

    // update freeze if freezeColumns or freezeRows props have changed
    const isFreezeColsUpdatedThroughProps = (this.props.freezeColumns !== prevProps.freezeColumns) &&
      (this.props.freezeColumns !== this.state.freeze.columns);
    const isFreezeRowsUpdatedThroughProps = (this.props.freezeRows !== prevProps.freezeRows) &&
      (this.props.freezeRows !== this.state.freeze.rows);
    if (isFreezeColsUpdatedThroughProps || isFreezeRowsUpdatedThroughProps) {
      this.setState({
        freeze: {
          columns: isFreezeColsUpdatedThroughProps ? this.props.freezeColumns : this.state.freeze.columns,
          rows: isFreezeRowsUpdatedThroughProps ? this.props.freezeRows : this.state.freeze.rows,
        },
      });
    }

    this.syncWidths();

    if (props.infiniteScroll) {
      this.setScrollerDimensions();

      if (this.props.fixedXScroller) {
        this.stickFixedScrollerToTable();
      }
    }
  }

  componentDidMount() {
    this.onTableChanged();

    this.syncWidths();
    this.onFontLoaded();

    if (this.props.infiniteScroll) {
      this.setScrollerDimensions();

      if (this.props.fixedXScroller) {
        this.stickFixedScrollerToTable();
        window.addEventListener('scroll', this.stickFixedScrollerToTable);
      }
    }

    window.addEventListener('resize', this.syncWidths);

    if (this.props.windowFreeResizeEvent) {
      this.iframe.contentWindow.addEventListener('resize', this.syncWidths);
    }

    this.setScrollProgress();
    window.addEventListener('scroll', this.setScrollProgress);
    if (this.props.infiniteScroll) {
      this.containerDown.addEventListener('scroll', this.setScrollProgress);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.syncWidths);
    if (this.props.windowFreeResizeEvent) {
      this.iframe.contentWindow.removeEventListener('resize', this.syncWidths);
    }

    if (this.props.infiniteScroll && this.props.fixedXScroller) {
      window.removeEventListener('scroll', this.stickFixedScrollerToTable);
    }

    window.removeEventListener('scroll', this.setScrollProgress);
  }

  onFontLoaded = () => {
    const font = new FontFaceObserver('Lato Regular');

    font.load().then(() => {
      this.syncWidths();
    });
  }

  tableHeaderElement = React.createRef();

  handleChangeSorting(data) {
    const { sorting } = this.state;

    this.setState({
      sorting: {
        ...sorting,
        field: data.sortingKey,
        compareFunc: data.compareFunc ? data.compareFunc : null,
        direction: sorting.field === data.sortingKey
          ? sorting.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
      },
      paging: this.paging,
    }, () => this.onTableChanged());
  }

  onTableChanged = () => {
    if (this.props.onChange) {
      const {
        sorting,
        paging,
      } = this.state;

      this.props.onChange({
        sorting,
        paging,
      });
    }
  };

  get paging() {
    return this.props.paging || this.state.paging;
  }

  getData() {
    let data = [];
    const {
      sorting,
    } = this.state;

    const paging = this.paging;

    if (this.props.server) {
      return [...this.props.data];
    } else {
      data = this.state.data.slice(); // copy array
    }

    if (sorting) {
      if (sorting.compareFunc) {
        data.sort(sorting.compareFunc.bind(null, sorting.direction));
      } else {
        if (sorting.direction === SORT_DIRECTION.ASC) {
          data.sort((a, b) => {
            return a[sorting.field] > b[sorting.field] ? 1 : -1;
          });
        } else {
          data.sort((a, b) => {
            return a[sorting.field] < b[sorting.field] ? 1 : -1;
          });
        }
      }
    }

    if (!paging) {
      return data;
    }

    const {
      activePage,
      itemsCountPerPage,
    } = paging;

    return data.slice((activePage - 1) * itemsCountPerPage, itemsCountPerPage * activePage);
  }

  handleContextMenu = (event, row, rowNumber, colNumber) => {
    event.preventDefault();

    this.setState({
      contextMenu: {
        row,
        rowNumber,
        colNumber,
        clientX: event.clientX,
        clientY: event.clientY,
      },
    });
  };

  renderBodyRow = (data, rowIndex, body, freezeColumns) => {
    const {
      tbodyRowHeight,
      rowKeyExtractor,
      rowClickHandler,
    } = this.props;

    const {
      hoveredRowIndex,
      freeze,
    } = this.state;

    return (
      <TableRow
        data={data}
        body={body}
        rowIndex={rowIndex}
        key={rowKeyExtractor(data, rowIndex)}
        onContextMenu={this.handleContextMenu}
        rowClickHandler={rowClickHandler}
        freezeColumns={freezeColumns}
        hoveredRowIndex={hoveredRowIndex}
        trOnMouseEnter={freeze.columns > 0 ? this.setHoveredRowIndex(rowIndex) : null}
        trOnMouseLeave={freeze.columns > 0 ? this.setHoveredRowIndex(null) : null}
        tdOnMouseOver={this.setHoveredColIndex}
        tdOnMouseLeave={this.setHoveredColIndex}
        tbodyRowHeight={tbodyRowHeight}
        isAllSelected={this.isAllSelected}
        isSomeSelected={this.isSomeSelected}
      />
    );
  }

  setHoveredRowIndex = (hoveredRowIndex) => () => this.setState({hoveredRowIndex})
  setHoveredColIndex = (hoveredColIndex) => this.setState({hoveredColIndex})

  renderBody(data, body, freezeColumns) {
    if (!body || !body.length) {
      return null;
    }

    if (!this.props.infiniteScroll && this.props.loading) {
      return (
        <tr className="table__loading">
          <td>
            Loading...
          </td>
        </tr>
      );
    }

    return data.map((i, k) => this.renderBodyRow(i, k, body, freezeColumns));
  }

  handlePerPageChange = (v) => {
    const {
      paging,
    } = this.state;

    this.setState({
      paging: {
        ...paging,
        itemsCountPerPage: v.value,
        activePage: 1,
      },
    }, () => {
      this.onTableChanged();
    });
  };

  handlePageChange = (page) => {
    const {
      paging,
    } = this.state;

    this.setState({
      paging: {
        ...paging,
        activePage: page,
      },
    }, () => {
      this.onTableChanged();
    });
  };

  handleFreezeRow = (row, index) => {
    const { freezeUpdateHandler } = this.props;
    this.setState({
      contextMenu: null,
      freeze: {
        ...this.state.freeze,
        rows: index,
      },
    }, () => (freezeUpdateHandler && freezeUpdateHandler(this.state.freeze)));
  };

  handleFreezeColumn = (column, index) => {
    const { freezeUpdateHandler } = this.props;
    this.setState({
      contextMenu: null,
      freeze: {
        ...this.state.freeze,
        columns: index,
      },
    }, () => freezeUpdateHandler && freezeUpdateHandler(this.state.freeze));
  };

  // if table component has fixed header or 'frozen' rows, then it consists of upper and lower tables,
  // which cells' widths need to be synchronized
  syncWidths = () => {
    // get lower table full row cells
    const downCells = [
      ...(this.tableDowns[0] ? this.tableDowns[0].querySelectorAll('tbody tr:first-child td') : []),
      ...(this.tableDowns[1] ? this.tableDowns[1].querySelectorAll('tbody tr:first-child td') : []),
    ];
    const cellsWidths = [];

    // check if table component has upper table and if yes get all its cells
    let upCells;
    if (this.tableUps.length) {
      upCells = [
        ...(this.tableUps[0] ? this.tableUps[0].querySelectorAll('thead td') : []),
        ...(this.tableUps[1] ? this.tableUps[1].querySelectorAll('thead td') : []),
      ];
    }

    [].forEach.call(downCells, (i, k) => {
      if (!i.offsetWidth) return; // @todo does this really needed?
      if (upCells) {
        upCells[k].style.minWidth = `${i.offsetWidth}px`;
        upCells[k].style.maxWidth = `${i.offsetWidth}px`;
      }
      cellsWidths.push(i.offsetWidth);
    });

    // to use widths out of current function
    this.cellsWidths = cellsWidths;
  }

  addTableUps = (el, index) => {
    if (!el || this.tableUps.indexOf(el) !== -1) {
      return;
    }

    this.tableUps[index] = el;
  }

  addTableDowns = (el, index) => {
    if (!el || this.tableDowns.indexOf(el) !== -1) {
      return;
    }

    this.tableDowns[index] = el;
  }

  addSyncedElement = (el) => {
    if (!el || this.syncedElements.indexOf(el) !== -1) {
      return;
    }

    this.syncedElements.push(el);
  }

  syncScroll = (e) => {
    // if nothing to sync with or if scroll event fired on element that is not under mouse now
    if ((this.syncedElements.length < 2) || (this.scrollTarget !== e.target)) {
      return;
    }

    // set scroll position for all table parts...
    this.syncedElements.forEach(i => {
      // ...except the part where the event was initiated (since this part has already actual scroll)
      if (i !== e.target) {
        i.scrollLeft = e.target.scrollLeft;
      }
    });

    this.setState({tableHorizontalScroll: e.target.scrollLeft}); // setState to update table header tooltip position
  }

  handleTableMouseMoveOrWheelEvent = (e) => {
    this.setCurrentScrollTarget(e.target);
  }

  // where scroll event was initiated
  setCurrentScrollTarget = (target) => {
    const scrollTarget = this.syncedElements.find(i => (i.contains(target) || i === target));
    if (this.scrollTarget !== scrollTarget) {
      this.scrollTarget = scrollTarget;
    }
  }

  // INFINITE X SCROLLER
  setScrollerDimensions = () => {
    const {
      fixedXScroller,
    } = this.props;

    const {
      fixScrollerAtBottom,
    } = this.state;

    const widthsContainer = this.table.querySelectorAll('.js-columns-widths-down');
    const scroller = this.scroller;
    const scrollerPlaceholder = scroller.querySelector('.js-scroller-placeholder');

    // necessary for scroller position calculating
    const isScrollerAbsolute = (!fixedXScroller || fixScrollerAtBottom); // if scroller is absolute or fixed positioned
    const containerBoundingClientRect = this.table.getBoundingClientRect();
    const fixedLeft = containerBoundingClientRect.left;
    const tableWidth = containerBoundingClientRect.width;

    // common values for table with freezed columns and without
    const cellsCols1 = widthsContainer[0].querySelectorAll('tbody tr:first-child td');
    const widthFirst = [].reduce.call(cellsCols1, (acc, current) => (acc + current.offsetWidth), 0); // width of the whole table or its left part if it has freezed columns

    // if freeze.columns > 1
    if (widthsContainer.length === 2) {
      const cellsCol2 = widthsContainer[1].querySelectorAll('tbody tr:first-child td');
      const widthLast = [].reduce.call(cellsCol2, (acc, current) => (acc + current.offsetWidth), 0); // width of right part of the table (exists only when table has freezed columns)

      // Place x scrollbar right under the table. Otherwise fix it at the bottom of viewport.
      if (isScrollerAbsolute) {
        scroller.style.left = `${widthFirst}px`; // move absolute scroller right on freezed columns width
      } else {
        scroller.style.left = `${fixedLeft + widthFirst}px`; // move fixed scroller right on table left position and freezed columns width
        scroller.style.width = `${tableWidth - widthFirst}px`; // set fixed scroller width to table width without freezed columns width
      }

      scrollerPlaceholder.style.width = `${widthLast}px`;
    } else {
      // Place x scrollbar right under the table. Otherwise fix it at the bottom of viewport.
      if (isScrollerAbsolute) {
        scroller.style.left = 0; // place absolute scroller to the left of table
      } else {
        scroller.style.left = `${fixedLeft}px`; // place fixed scroller to the left of table
        scroller.style.width = `${tableWidth}px`; // set fixed scroller width to table width
      }

      scrollerPlaceholder.style.width = `${widthFirst}px`;
    }
  }

  // sticks fixed scroller to table's bottom when table is scrolled up/down
  stickFixedScrollerToTable = () => {
    if (!this.containerTable) {
      return;
    }

    const containerBoundingClientRect = this.containerTable.getBoundingClientRect();
    const tableTop = containerBoundingClientRect.top;
    const tableHeight = containerBoundingClientRect.height;
    this.scrollerBottom = this.scrollerBottom || parseInt(window.getComputedStyle(this.scroller).bottom, 10);
    const viewportHeight = window.innerHeight;

    // if table is scrolled up/down
    const isScrolledUp = (viewportHeight - tableTop - tableHeight) > this.scrollerBottom;
    const isScrolledDown = tableTop > viewportHeight;

    if (isScrolledUp || isScrolledDown) {
      !this.state.fixScrollerAtBottom && this.setState({ fixScrollerAtBottom: true });
    } else {
      this.state.fixScrollerAtBottom && this.setState({ fixScrollerAtBottom: false });
    }
  }

  getTableHeight = (rows, skeletonsNumber = 0, addition = 0) => {
    return `${this.props.theadRowHeight + (rows + skeletonsNumber) * this.props.tbodyRowHeight + addition}px`;
  }

  getLoader = (data, body) => {
    const {
      skeletonRowsNumber,
      tbodyRowHeight,
    } = this.props;

    return data ? (
      [...new Array(skeletonRowsNumber)].map((i, k) => (
        <TableRow isSkeleton data={data} body={body} tbodyRowHeight={tbodyRowHeight} key={k} />
      ))
    ) : null;
  }

  handleTheadOnMouseEnterLeave = (tooltip) => () => this.setState({tooltip})

  renderTableHeaderRow = (header, hoveredColIndex, fixedHeader, freezeColumns = 0) => {
    const {
      draggable,
      onDragEnd,
      theadRowHeight,
    } = this.props;

    const {
      sorting,
    } = this.state;

    return (
      <thead>
        <TableHeaderRow
          header={header}
          sorting={sorting}
          onSortingChanged={this.handleChangeSorting}
          draggable={draggable}
          onDragEnd={onDragEnd}
          hoveredColIndex={hoveredColIndex}
          theadRowHeight={theadRowHeight}
          handleTheadOnMouseEnterLeave={this.handleTheadOnMouseEnterLeave}
          freezeColumns={freezeColumns}
          fixedHeader={fixedHeader}
        />
      </thead>
    );
  }

  setScrollProgress = () => {
    // if needed ref is not exist
    if (!this.containerUp) {
      return;
    }

    const {
      offsetTop,
      infiniteScroll,
      unsortedData,
      tbodyRowHeight,
    } = this.props;
    let { totalItemsCount, } = this.props;
    const { freeze, } = this.state;

    const containerUpTop = this.containerUp.getBoundingClientRect().top;
    const containerDownTop = this.containerDown.getBoundingClientRect().top;

    // part of table scrolled by means of window scroll
    // relative to sticky table element
    let scrolledUpPixels = containerUpTop - containerDownTop;

    let innerTableScroll = 0;
    // exists only with infiniteScroll
    if (infiniteScroll) {
      // infinite content is scrolled inside containerDown
      const infiniteContentTop = this.containerDownRight.getBoundingClientRect().top;
      innerTableScroll = containerDownTop - infiniteContentTop;
    }

    // sum of 'window scrolled' and 'infinite scrolled' pixels
    const totalScrolledPixels = scrolledUpPixels + innerTableScroll;

    // count pixels that cannot be scrolled up (under sticky element),
    // due to not enough space under table
    const pixelsAfterTable = document.body.scrollHeight - (
      window.pageYOffset + this.containerDown.getBoundingClientRect().bottom
    );
    let remainedUnScrolled = window.innerHeight - (
      offsetTop + this.containerUp.offsetHeight + pixelsAfterTable
    );
    if (remainedUnScrolled < 0) {
      remainedUnScrolled = 0;
    }

    totalItemsCount = totalItemsCount || this.props.data.length;

    const percent = totalScrolledPixels / (
      (
        (totalItemsCount - (
          freeze.rows ? (freeze.rows - unsortedData.length) : 0)
        ) * tbodyRowHeight
      ) - remainedUnScrolled
    ) * 100;

    this.setState({ tableScrollProgress: percent });
  }

  render() {
    const {
      className,
      title,
      header,
      body,
      enableContextMenu,
      unsortedData,
      infiniteScroll,
      draggable,
      windowFreeResizeEvent,
      fixedXScroller,
      InfiniteScrollComponent,
      skeletonRowsNumber, // number of skeleton rows in infinite-scroll table
      theadRowHeight,
    } = this.props;

    let {
      offsetTop,
    } = this.props;

    const {
      contextMenu,
      fixScrollerAtBottom,
      hoveredColIndex,
      freeze,
      tooltip,
      tableHorizontalScroll,
      tableScrollProgress,
    } = this.state;

    let freezeRows = freeze.rows;
    let freezeColumns = freeze.columns;

    if (!offsetTop && freezeRows > 0) offsetTop = 0;

    let data = this.getData();
    const unsortedDataLength = (unsortedData && unsortedData.length) || 0;
    if (unsortedDataLength) {
      unsortedData.forEach(i => { i.rowClassName = 'tr-unsorted'; });
      data = [...unsortedData, ...data];
      freezeRows = freeze.rows ? freeze.rows : unsortedDataLength;
    }

    let tableHeaderStyle = {};
    let tableContentHeaderStyle = {};

    // if offsetTop is number
    const fixedHeader = !!(freezeRows || (!isNaN(parseFloat(offsetTop)) && isFinite(offsetTop)));
    if (fixedHeader) {
      tableHeaderStyle.top = `${offsetTop}px`;
      if (this.tableHeaderElement.current) {
        tableContentHeaderStyle.top = `${offsetTop + this.tableHeaderElement.current.getBoundingClientRect().height}px`;
      } else {
        tableContentHeaderStyle.top = `${offsetTop}px`;
      }
    }

    const paging = this.paging;

    // when infinite-scroll table exists - check if it was ended up
    let skeletonsNumber = skeletonRowsNumber;
    if (InfiniteScrollComponent && !InfiniteScrollComponent.hasMore) {
      skeletonsNumber = 0;
    }

    // check if table has checkbox column @todo take in account case when className is set with function
    const hasTableCheckboxColumn = !!body.filter(i => typeof i.className === 'string' && i.className.indexOf('_checkbox') !== -1).length;

    // to update total checkbox when all or something checked/unchecked
    this.isAllSelected = (data.length - unsortedDataLength) === data.filter(i => i.rowClassName === '_selected').length;
    this.isSomeSelected = data.filter(i => i.rowClassName === '_selected').length > 0;

    return (
      <div
        className={`${styles.container} ${className || ''} ${draggable ? '_dnd' : ''} table`}
        ref={el => { this.table = el; }}
      >
        <TableHeader
          title={title}
          fixedHeader={fixedHeader}
          innerRef={this.tableHeaderElement}
          style={tableHeaderStyle}
          paging={paging}
          onPerPageChanged={this.handlePerPageChange}
          onPaginationChanged={this.handlePageChange}
        />
        <div
          className={`${styles.containerTable} ${unsortedDataLength % 2 === 0 ? '' : '_odd-stripes'}`}
          ref={el => { this.containerTable = el; }}
          onMouseMove={this.handleTableMouseMoveOrWheelEvent}
          onWheel={this.handleTableMouseMoveOrWheelEvent}
        >
          {tooltip &&
            <div className={styles.tooltipWrapper} style={{...tableContentHeaderStyle}}>
              <TableHeaderTooltip
                tooltip={tooltip}
                cellsWidths={this.cellsWidths}
                top={theadRowHeight}
                scrollLeft={tableHorizontalScroll}
                freezeColumns={freezeColumns}
              />
            </div>
          }
          {fixedHeader &&
            <div
              ref={el => { this.containerUp = el; }}
              className={`${styles.containerUp}`}
              style={{ ...tableContentHeaderStyle, overflowY: infiniteScroll ? 'scroll' : '' }}
            >
              {!!freezeColumns &&
                <div
                  className={`${styles.containerLeft}`}
                  style={{ height: this.getTableHeight(freezeRows) }}
                  ref={el => { this.addTableUps(el, 0); }}
                >
                  <table>
                    {this.renderTableHeaderRow(
                      header.slice(0, freezeColumns),
                      hoveredColIndex < freezeColumns ? hoveredColIndex : undefined,
                      fixedHeader,
                    )}
                    <tbody>
                      {this.renderBody(
                        data.slice(0, freezeRows),
                        body.slice(0, freezeColumns)
                      )}
                    </tbody>
                  </table>
                </div>
              }
              <div
                className={`${styles.containerRight}`}
                style={{ height: this.getTableHeight(freezeRows) }}
              >
                <div
                  className={`${styles.containerRightScroller}`}
                  style={{ height: this.getTableHeight(freezeRows, 0, 20) }}
                  ref={el => {
                    this.addTableUps(el, 1);
                    this.addSyncedElement(el);
                  }}
                  onScroll={this.syncScroll}
                >
                  <table>
                    {this.renderTableHeaderRow(
                      header.slice(freezeColumns),
                      hoveredColIndex >= freezeColumns ? (hoveredColIndex - freezeColumns) : undefined,
                      fixedHeader,
                      freezeColumns,
                    )}
                    {!!freezeRows && <tbody>
                      {this.renderBody(
                        data.slice(0, freezeRows),
                        body.slice(freezeColumns),
                        freezeColumns,
                      )}
                    </tbody>}
                  </table>
                </div>
              </div>
              {tableScrollProgress !== undefined &&
                <div
                  className={`table-progress-bar ${styles.containerTableProgress}`}
                  style={{
                    height: `${theadRowHeight}px`,
                    backgroundImage: `linear-gradient(90deg,
                      rgba(0,0,0,.05) ${this.state.tableScrollProgress}%,
                      transparent ${this.state.tableScrollProgress}%
                    )`,
                  }}
                />
              }
            </div>
          }
          <div
            ref={el => { this.containerDown = el; }}
            className={`${styles.containerDown}`}
            style={{ marginTop: fixedHeader ? `-${this.getTableHeight(freezeRows)}` : '', maxHeight: infiniteScroll ? '100vh' : '' }}
          >
            {!!freezeColumns &&
              <div className={`${styles.containerLeft} js-columns-widths-down`} ref={el => { this.addTableDowns(el, 0); }}>
                <table>
                  {this.renderTableHeaderRow(
                    header.slice(0, freezeColumns),
                    (!fixedHeader && (hoveredColIndex < freezeColumns)) ? hoveredColIndex : undefined,
                    fixedHeader,
                  )}
                  {!infiniteScroll ? (
                    <tbody>
                      {this.renderBody(
                        data,
                        body.slice(0, freezeColumns)
                      )}
                    </tbody>
                  ) : (
                    <InfiniteScroll
                      {...InfiniteScrollComponent}
                      element="tbody"
                      getScrollParent={(el) => el && el.closest(`.${styles.containerDown}`)}
                      loader={this.getLoader(data[0], body.slice(0, freezeColumns))}
                    >
                      {this.renderBody(
                        data,
                        body.slice(0, freezeColumns)
                      )}
                    </InfiniteScroll>
                  )}
                </table>
              </div>
            }
            <div
              ref={el => { this.containerDownRight = el; }}
              className={`${styles.containerRight}`}
              style={{ height: infiniteScroll ? this.getTableHeight(data.length, skeletonsNumber) : '' }}
            >
              <div
                className={`${styles.containerRightScroller} js-columns-widths-down`}
                style={{ height: infiniteScroll ? this.getTableHeight(data.length, skeletonsNumber, 20) : '' }}
                ref={el => {
                  this.addTableDowns(el, 1);
                  this.addSyncedElement(el);
                }}
                onScroll={this.syncScroll}
              >
                <table>
                  {this.renderTableHeaderRow(
                    header.slice(freezeColumns),
                    (!fixedHeader && (hoveredColIndex >= freezeColumns)) ? (hoveredColIndex - freezeColumns) : undefined,
                    fixedHeader,
                    freezeColumns,
                  )}
                  {!infiniteScroll ? (
                    <tbody>
                      {this.renderBody(
                        data,
                        body.slice(freezeColumns),
                        freezeColumns,
                      )}
                    </tbody>
                  ) : (
                    <InfiniteScroll
                      {...InfiniteScrollComponent}
                      element="tbody"
                      getScrollParent={(el) => el && el.closest(`.${styles.containerDown}`)}
                      loader={this.getLoader(data[0], body.slice(freezeColumns))}
                    >
                      {this.renderBody(
                        data,
                        body.slice(freezeColumns),
                        freezeColumns
                      )}
                    </InfiniteScroll>
                  )}
                </table>
              </div>
            </div>
          </div>
          {infiniteScroll &&
            <div
              className={`${styles.scroller} ${(fixedXScroller && !fixScrollerAtBottom) ? '_fixed' : ''}`}
              ref={el => {
                this.scroller = el;
                this.addSyncedElement(el);
              }}
              onScroll={this.syncScroll}
            >
              <div className={`${styles.scrollerPlaceholder} js-scroller-placeholder`} />
            </div>
          }

          {windowFreeResizeEvent &&
            <iframe ref={el => { this.iframe = el; }} className={styles.iframe} />
          }

          {contextMenu && enableContextMenu &&
            <TableContextMenu
              {...contextMenu}
              onClose={() => this.setState({ contextMenu: null })}
              onFreezeRow={this.handleFreezeRow}
              onFreezeColumn={this.handleFreezeColumn}
              unsortedDataLength={unsortedDataLength}
              hasTableCheckboxColumn={hasTableCheckboxColumn}
            />
          }
        </div>
      </div>
    );
  }
}

Table.defaultProps = {
  paging: null,
  header: [],
  body: [],
  server: false,
  loading: false,
  fixedHeader: false,
  sorting: {
    field: null,
    direction: null,
  },
  rowClickHandler: () => { },
  enableContextMenu: true,
  freezeRows: 0,
  freezeColumns: 0,
  infiniteScroll: false,
  draggable: false,
  onDragEnd: () => { },
  windowFreeResizeEvent: false,
  fixedXScroller: false,
  skeletonRowsNumber: 5,
  tbodyRowHeight: 32,
  theadRowHeight: 32,
};

Table.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  rowKeyExtractor: PropTypes.func.isRequired,
  server: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  fixedHeader: PropTypes.bool,
  offsetTop: PropTypes.number,
  header: tablePropTypes.header,
  sorting: tablePropTypes.sorting,
  body: tablePropTypes.body,
  paging: tablePropTypes.paging,
  rowClickHandler: PropTypes.func,
  enableContextMenu: PropTypes.bool,
  unsortedData: PropTypes.array,
  freezeRows: PropTypes.number,
  freezeColumns: PropTypes.number,
  infiniteScroll: PropTypes.bool,
  InfiniteScrollComponent: PropTypes.shape({
    loadMore: PropTypes.func,
    hasMore: PropTypes.bool,
    pageStart: PropTypes.number,
    useWindow: PropTypes.bool,
  }),
  skeletonRowsNumber: PropTypes.number,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  freezeUpdateHandler: PropTypes.func,
  windowFreeResizeEvent: PropTypes.bool,
  fixedXScroller: PropTypes.bool,
  tbodyRowHeight: PropTypes.number,
  theadRowHeight: PropTypes.number,
  totalItemsCount: PropTypes.number,
};
