import React from 'react';
import { sortableContainer } from 'react-sortable-hoc';

export const SortableContainer = sortableContainer(({ children, onAttachedRef }) => {
  return (
    <div
      className={`select__list`}
      ref={el => {
        onAttachedRef(el);
      }}>{children}</div>
  );
});
