import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tablePropTypes } from './PropTypes';
import { Pagination } from '../Pagination';
import { Select } from '../Select';

export class TablePaging extends Component {
  shouldComponentUpdate(nextProps) {
    return !(nextProps.paging === this.props.paging);
  }

  perPageValues = [
    10, 25, 50, 100,
  ].map(v => ({ label: v, value: v }));

  perPageMap = {
    10: this.perPageValues[0],
    25: this.perPageValues[1],
    50: this.perPageValues[2],
    100: this.perPageValues[3],
  };

  handlePaginationChange = (page) => {
    this.props.onPaginationChanged(page);
  };

  handlePerPageChange = (data) => {
    this.props.onPerPageChanged(data);
  };

  render() {
    const {
      paging,
    } = this.props;

    const {
      itemsCountPerPage,
      totalItemsCount,
    } = paging;

    const showPagination = itemsCountPerPage < totalItemsCount;

    return (
      <div className="table__paging">
        {showPagination &&
        <Pagination
          {...paging}
          onChange={this.handlePaginationChange}
        />
        }
        <Select
          options={this.perPageValues}
          value={this.perPageMap[paging.itemsCountPerPage]}
          onChange={this.handlePerPageChange}
        />
      </div>
    );
  }
}

TablePaging.propTypes = {
  paging: tablePropTypes.paging,
  onPaginationChanged: PropTypes.func.isRequired,
  onPerPageChanged: PropTypes.func.isRequired,
};
