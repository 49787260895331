import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_tabs.scss';
import { isEqual } from '../utils/compare';

export class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    return !(isEqual([
      'className',
      'value',
      'items',
    ], this.props, nextProps));
  }

  render() {
    const {
      className,
      value,
      items = [],
      onChange = () => {},
    } = this.props;

    return (
      <div className={`tabs ${className}`}>
        {items.map((item, index) => {
          const active = item.value === value;
          return <button key={index} onClick={() => onChange(item)} className={`tabs__btn ${active ? '_active': ''}`}>{item.title}</button>
        })}
      </div>
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any,
  })),
  onChange: PropTypes.func,
};