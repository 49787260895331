import React from 'react';
import ReactDOM from 'react-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';

export function withClickOutside(WrappedComponent) {
  class EnhancedComponent extends React.Component {
    __domNode;
    __wrappedInstance;
    handleClickOutside;

    constructor(props) {
      super(props);

      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = e => {
      const domNode = this.__domNode;
      if (
        (!domNode || !domNode.contains(e.target)) &&
        this.__wrappedInstance &&
        typeof this.__wrappedInstance.handleClickOutside === 'function'
      ) {
        this.__wrappedInstance.handleClickOutside(e);
      }
    }

    render() {
      const { wrappedRef, ...rest } = this.props;

      return (
        <WrappedComponent
          {...rest}
          ref={c => {
            this.__wrappedInstance = c;
            this.__domNode = ReactDOM.findDOMNode(c);
            wrappedRef && wrappedRef(c);
          }}
        />
      );
    }
  }

  EnhancedComponent.displayName = `withClickOutside(${WrappedComponent.displayName || WrappedComponent.name})`;

  return hoistNonReactStatic(EnhancedComponent, WrappedComponent);
}
