import React from 'react';
import PropsTypes from 'prop-types';
import classes from './clockPointer/ClockPointer.module.scss';
import { ClockTypes } from './utils';

export class ClockPointer extends React.Component {
  static propTypes = {
    value: PropsTypes.number.isRequired,
    hasSelected: PropsTypes.bool,
    type: PropsTypes.oneOf(['hours', 'minutes', 'seconds']),
    isInner: PropsTypes.bool,
  };

  static getDerivedStateFromProps = (
    nextProps,
    state,
  ) => {
    if (nextProps.type !== state.previousType) {
      return {
        toAnimateTransform: true,
        previousType: nextProps.type,
      };
    }

    return {
      toAnimateTransform: false,
      previousType: nextProps.type,
    };
  };

  state = {
    toAnimateTransform: false,
    previousType: undefined,
  };

  getAngleStyle = () => {
    const { value, type, isInner } = this.props;

    const max = type === ClockTypes.HOURS ? 12 : 60;
    let angle = (360 / max) * value;

    if (type === ClockTypes.HOURS && value > 12) {
      angle -= 360; // round up angle to max 360 degrees
    }

    return {
      height: isInner ? '26%' : '40%',
      transform: `rotateZ(${angle}deg)`,
    };
  };

  render() {
    const { hasSelected } = this.props;
    const { toAnimateTransform } = this.state;
    return (
      <div
        style={this.getAngleStyle()}
        className={`${classes.pointer} ${toAnimateTransform ? classes.animateTransform : ''}`}
      >
        <div
          className={`${classes.thumb} ${hasSelected ? classes.noPoint : ''}`}
        />
      </div>
    );
  }
}
