import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tablePropTypes } from './PropTypes';
import { TablePaging } from './TablePaging';

export class TableHeader extends Component {
  shouldComponentUpdate(nextProps) {
    return !(nextProps.title === this.props.title &&
      nextProps.fixedHeader === this.props.fixedHeader &&
      nextProps.paging === this.props.paging
    );
  }

  render() {
    const {
      title,
      fixedHeader = '',
      innerRef,
      style = {},
      paging,
      onPaginationChanged,
      onPerPageChanged,
    } = this.props;

    const classNames = ['table__header', fixedHeader ? 'table__header_fixed' : ''];
    return (
      <div
        className={classNames.join(' ').trim()}
        ref={innerRef}
        style={style}
      >
        <div className={`table__top`}>
          <div className="table__title title-card">{title}</div>
          {paging && <TablePaging
            paging={paging}
            onPaginationChanged={onPaginationChanged}
            onPerPageChanged={onPerPageChanged}
          />
          }
        </div>
      </div>
    );
  }
}

TableHeader.propTypes = {
  title: PropTypes.string,
  fixedHeader: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(window.Element) }),
  ]),
  style: PropTypes.object,
  paging: tablePropTypes.paging,
  onPaginationChanged: PropTypes.func.isRequired,
  onPerPageChanged: PropTypes.func.isRequired,
};
