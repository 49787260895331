import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './timePicker/TimePicker.module.scss';
import { ClockView } from './timePicker/ClockView';
import { arrayIncludes, ClockTypes } from './timePicker/utils';
import { convertToMeridiem, getMeridiem } from './timePicker/time-utils';
import { Switch } from './Switch';

export class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openTo: (props.openTo && arrayIncludes(props.views, props.openTo)) ? props.openTo : props.views[0],
      mode: getMeridiem(props.value),
      time: moment(props.value),
    };
  }

  static propTypes = {
    value: PropTypes.number.isRequired,
    ampm: PropTypes.bool,
    minutesStep: PropTypes.number,
    openTo: PropTypes.oneOf(Object.values(ClockTypes)),
    views: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ClockTypes))),
    onChange: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['sm', '']),
    useViewsAsTabs: PropTypes.bool,
  };

  static defaultProps = {
    ampm: true,
    views: ['hours', 'minutes'],
    size: '',
    useViewsAsTabs: false,
  };

  get timeOptions() {
    return this.props.views.map(v => {
      return {
        title: v,
        value: v,
      };
    });
  }

  get openTo() {
    return this.props.openTo || this.state.openTo;
  }

  handleChange = (value, isFinish) => {
    const { views, onChange } = this.props;
    const { openTo } = this.state;
    const nextViewToOpen = views[views.indexOf(openTo) + 1];

    this.setState({ time: value });

    if (isFinish && nextViewToOpen) {
      // do not close picker if needs to show next view
      onChange(value, false);
      this.setState({
        openTo: nextViewToOpen,
      });
      return;
    }

    onChange(value, Boolean(isFinish));
  };

  handleMeridiemChange = () => {
    const { mode: oldMode } = this.state;
    const mode = oldMode === 'am' ? 'pm' : 'am';
    const { value, ampm } = this.props;
    this.setState({ mode });
    const timeWithMeridiem = convertToMeridiem(value, mode, Boolean(ampm));
    this.handleChange(timeWithMeridiem, false);
  };

  handleChangeTimeView = (view) => {
    const { views } = this.props;
    const { openTo } = this.state;

    const nextViewToOpen = views[views.indexOf(openTo) + 1] || views[0];
    this.setState({
      openTo: nextViewToOpen,
    });
  };

  render() {
    const { value, ampm, minutesStep, size, useViewsAsTabs } = this.props;
    const { mode, time } = this.state;
    const modeValue = !!(mode === 'pm');

    return (
      <div className={`${styles.container} ${useViewsAsTabs ? styles.withTabs : ''} timepicker`}>
        <div className={styles.mode}>
          <Switch
            size={useViewsAsTabs ? 'sm' : ''}
            list={['AM', 'PM']}
            name="time-mode-swithcer"
            onChange={this.handleMeridiemChange}
            value={modeValue}
          />
        </div>
        {!useViewsAsTabs
          ? <div className={`${styles.button} ${styles.buttonView}`} onClick={this.handleChangeTimeView}>
            <span>{this.openTo}</span>
          </div>
          : <div className={`${styles.time}`} onClick={this.handleChangeTimeView}>
            <span>{time.format('hh:mm A')}</span>
          </div>
        }
        <ClockView
          type={this.openTo}
          date={value}
          ampm={ampm}
          minutesStep={minutesStep}
          onHourChange={this.handleChange}
          onMinutesChange={this.handleChange}
          onSecondsChange={this.handleChange}
          size={size}
        />
      </div>
    );
  }
}
