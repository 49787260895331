import React from 'react';
import PropTypes from 'prop-types';

export const ContextMenuListItem = ({className = '', children, title = false, ...rest}) => {
  let content;

  if (title) {
    content = <div className="context-menu__title">{children}</div>;
  } else {
    content = children;
  }
  return (
    <li className={`context-menu__list-item ${title ? '_title' : ''} ${className}`} {...rest}>
      {content}
    </li>
  );
};

ContextMenuListItem.propTypes = {
  title: PropTypes.bool,
  className: PropTypes.string,
};
