import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '../Tabs';
import { momentOrDatePropCheck } from './utils';
import { TimePicker } from '../TimePicker';
import styles from './DatesPickerWithTimePicker/DatesPickerWithTimePicker.module.scss';
import moment from 'moment';

const tabs = [
  { title: 'Date', value: 'date' },
];

export class DatesPickerWithTimePicker extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.oneOfType([PropTypes.number, momentOrDatePropCheck]),
      PropTypes.shape({
        start: PropTypes.oneOfType([PropTypes.number, momentOrDatePropCheck]),
        end: PropTypes.oneOfType([PropTypes.number, momentOrDatePropCheck]),
      }),
    ]),
    withTimePicker: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    clearAfterSelection: PropTypes.func,
    toggleToTime: PropTypes.bool,
  };

  state = {
    tab: 'date',
  };

  tabs = tabs.concat(['hours', 'minutes'].map(view => {
    return {
      title: view,
      value: view,
    };
  }));

  handleChangeTab = (tab) => {
    this.setState({
      tab: tab.value,
    });
    this.props.clearAfterSelection();
  };

  handleChange = (value) => {
    this.props.onChange(value);
  };

  renderTimePicker = () => {
    const { value } = this.props;
    const { tab } = this.state;
    const time = value ? value.valueOf() : moment().startOf('day').valueOf();

    return (
      <div className={styles.pickerWrapper}>
        <TimePicker openTo={tab} value={time} onChange={this.handleChange} size={'sm'} useViewsAsTabs={true} />
      </div>
    );
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.toggleToTime && !prevProps.toggleToTime && this.state.tab === 'date') {
      return true;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      this.handleChangeTab(this.tabs[1]);
    }
  }

  renderBar = () => {
    const { tab } = this.state;
    const { children } = this.props;
    return (
      <React.Fragment>
        <Tabs items={this.tabs} className={`tabs_small tabs_capitalized ${styles.tabs}`} value={tab} onChange={this.handleChangeTab} />
        {tab === 'date'
          ? children
          : this.renderTimePicker()
        }
      </React.Fragment>
    );
  };

  render() {
    const { withTimePicker, children } = this.props;

    return (
      <React.Fragment>
        {withTimePicker
          ? this.renderBar()
          : children
        }
      </React.Fragment>
    );
  }
}
