import React, { Component } from 'react';
import { TableCell } from './TableCell';
import PropTypes from 'prop-types';
import { tablePropTypes } from './PropTypes';
import isEqual from 'lodash/isEqual';
import { isEqual as _isEqual } from '../../utils/compare';

export class TableRow extends Component {
  shouldComponentUpdate(nextProps) {
    let isEqualRowIndex = true;

    if (this.props.rowIndex === this.props.hoveredRowIndex) {
      if (this.props.rowIndex !== nextProps.hoveredRowIndex) {
        isEqualRowIndex = false;
      }
    } else if (this.props.rowIndex === nextProps.hoveredRowIndex) {
      isEqualRowIndex = false;
    }

    return !(
      _isEqual([
        'rowIndex',
        'freezeColumns',
        'onContextMenu',
        'rowClickHandler',
        'isAllSelected',
        'isSomeSelected',
        'isSkeleton',
      ], this.props, nextProps) &&
      isEqual(nextProps.data, this.props.data) &&
      isEqual(nextProps.body, this.props.body) &&
      isEqualRowIndex
    );
  }

  render() {
    const {
      rowIndex,
      data,
      body,
      freezeColumns = 0,
      onContextMenu,
      rowClickHandler,
      hoveredRowIndex,
      trOnMouseEnter,
      trOnMouseLeave,
      tdOnMouseOver,
      tdOnMouseLeave,
      isSkeleton,
      tbodyRowHeight,
      isAllSelected,
      isSomeSelected,
    } = this.props;

    let trProps;
    if (!isSkeleton) {
      trProps = {
        onClick: (e) => rowClickHandler(e, data, rowIndex),
        className: `${hoveredRowIndex === rowIndex ? 'tr-hover' : ''} ${data.rowClassName || ''}`,
        onMouseEnter: trOnMouseEnter,
        onMouseLeave: trOnMouseLeave,
      };
    } else {
      trProps = { className: `loader` };
    }

    return (
      <tr {...trProps}>
        {body.map((field, colIndex) => {

          let tdProps = {
            field: field,
            data: data,
            tbodyRowHeight: tbodyRowHeight,
          };
          if (!isSkeleton) {
            tdProps = {
              ...tdProps,
              colIndex: colIndex,
              onContextMenu: onContextMenu,
              rowIndex: rowIndex,
              freezeColumns: freezeColumns,
              tdOnMouseOver: tdOnMouseOver,
              tdOnMouseLeave: tdOnMouseLeave,
              isAllSelected: isAllSelected,
              isSomeSelected: isSomeSelected,
            };
          } else {
            tdProps = {
              ...tdProps,
              isSkeleton: true,
            };
          }

          return <TableCell {...tdProps} key={colIndex} />
        })}
      </tr>
    );
  }
}

TableRow.propTypes = {
  body: tablePropTypes.body,
  freeze: tablePropTypes.freeze,
  rowIndex: PropTypes.number,
  data: PropTypes.any,
  onContextMenu: PropTypes.func,
  rowClickHandler: PropTypes.func,
  freezeColumns: PropTypes.number,
  hoveredRowIndex: PropTypes.number,
  trOnMouseEnter: PropTypes.func,
  trOnMouseLeave: PropTypes.func,
  tdOnMouseOver: PropTypes.func,
  tdOnMouseLeave: PropTypes.func,
  isSkeleton: PropTypes.bool,
  tbodyRowHeight: PropTypes.number,
};
