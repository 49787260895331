import React from 'react';
import PropTypes from 'prop-types';
import { getHours, getMinutes } from './time-utils';
import styles from './clock/Clock.module.scss';
import { ClockPointer } from './ClockPointer';
import { ClockTypes } from './utils';

export class Clock extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(Object.keys(ClockTypes).map(key => ClockTypes[key])).isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    ampm: PropTypes.bool,
    minutesStep: PropTypes.number,
    size: PropTypes.oneOf(['sm', '']),
    // innerRef: PropTypes.any,
  };

  static defaultProps = {
    ampm: false,
    minutesStep: 1,
  };

  isMoving = false;

  setTime(e, isFinish = false) {
    let { offsetX, offsetY } = e;

    if (typeof offsetX === 'undefined') {
      const rect = e.target.getBoundingClientRect();

      offsetX = e.changedTouches[0].clientX - rect.left;
      offsetY = e.changedTouches[0].clientY - rect.top;
    }

    const value =
      this.props.type === ClockTypes.SECONDS || this.props.type === ClockTypes.MINUTES
        ? getMinutes(offsetX, offsetY, this.props.minutesStep)
        : getHours(offsetX, offsetY, Boolean(this.props.ampm));

    this.props.onChange(value, isFinish);
  }

  handleTouchMove = (e) => {
    this.isMoving = true;
    this.setTime(e);
  };

  handleTouchEnd = (e) => {
    if (this.isMoving) {
      this.setTime(e, true);
      this.isMoving = false;
    }
  };

  handleMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // MouseEvent.which is deprecated, but MouseEvent.buttons is not supported in Safari
    const isButtonPressed =
      typeof e.buttons === 'undefined' ? e.nativeEvent.which === 1 : e.buttons === 1;

    if (isButtonPressed) {
      this.setTime(e.nativeEvent, false);
    }
  };

  handleMouseUp = (e) => {
    if (this.isMoving) {
      this.isMoving = false;
    }

    this.setTime(e.nativeEvent, true);
  };

  hasSelected = () => {
    const { type, value } = this.props;

    if (type === ClockTypes.HOURS) {
      return true;
    }

    return value % 5 === 0;
  };

  render() {
    const { value, children, type, ampm, size } = this.props;

    const isPointerInner = !ampm && type === ClockTypes.HOURS && (value < 1 || value > 12);

    return (
      <div className={`${styles.container} ${size ? styles[`container${size}`] : ''} clock__container`}>
        <div className={`${styles.clock} ${size ? styles[`clock${size}`] : ''} clock`}>
          <div
            role="menu"
            tabIndex={-1}
            className={styles.squareMask}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMove}
          />

          <div className={styles.pin} />

          <ClockPointer
            value={value}
            type={type}
            hasSelected={this.hasSelected()}
            isInner={isPointerInner}
          />

          {children}
        </div>
      </div>
    );
  }
}
