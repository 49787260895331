import React, {Component} from 'react';
import { Icon } from './Icon';
import PropTypes from 'prop-types';

import styles from './layout/layout.module.scss';

export class Layout extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.toggleSidebarOnResize);
    this.toggleSidebarOnResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.toggleSidebarOnResize);
  }

  toggleSidebarOnResize = () => {
    const {
      toggleSidebar,
    } = this.props;

    if (this.windowWidth === undefined) {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth < 1280) {
        toggleSidebar(false);
      }
    } else {
      if (this.windowWidth < 1280) {
        this.windowWidth = window.innerWidth;
      } else {
        if (window.innerWidth < 1280) {
          this.windowWidth = window.innerWidth;
          this.props.opened && toggleSidebar(false);
        }
      }
    }
  }

  render() {
    const {
      className = '',
      opened = true,
      toggleSidebar,
      sidebar,
      children,
    } = this.props;

    return (
      <div className={`${styles.container} ${opened ? styles._sidebarOpened : ''} ${className}`}>
        <aside className={styles.sidebar}>
          {sidebar}
        </aside>
        <main className={`${styles.main}`}>
          {children}
          <button
            className={styles.arrow}
            onClick={toggleSidebar}
          >
            <Icon name="Left" />
          </button>
        </main>
      </div>
    )
  }
}

Layout.propTypes = {
  className: PropTypes.string,
  opened: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  sidebar: PropTypes.element,
}
