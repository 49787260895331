import moment  from 'moment';

export const ClockTypes = {
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
};

export function arrayIncludes(array, itemOrItems) {
  if (Array.isArray(itemOrItems)) {
    return itemOrItems.every(item => array.indexOf(item) !== -1);
  }

  return array.indexOf(itemOrItems) !== -1;
}

const getMoment = date => {
  if (moment.isMoment(date)) {
    return date;
  }
  return moment(date);
};

export const getHoursValue = d => {
  const date = getMoment(d);

  return date.hour();
};

export const getMinutesValue = d => {
  const date = getMoment(d);

  return date.minute();
};

export const getSecondsValue = d => {
  const date = getMoment(d);

  return date.second();
};

export const setHoursValue = (t, hours) => {
  const time = getMoment(t);

  return time.set('h', hours);
};

export const setMinutesValue = (t, minutes) => {
  const time = getMoment(t);

  return time.set('m', minutes);
};

export const setSecondsValue = (t, seconds) => {
  const time = getMoment(t);

  return time.set('s', seconds);
};
