import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../scss/_collapse.scss';
import { Icon } from './Icon';

export class Collapse extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };
  }

  handleToggle = () => {
    this.setState((state) => {
      return {
        isOpened: !state.isOpened,
      };
    });
  };

  render() {
    const {
      className = '',
      title,
      children,
    } = this.props;

    const {
      isOpened,
    } = this.state;

    const classes = ['collapse', className];

    if (isOpened) {
      classes.push('collapse_opened');
    }

    return (
      <div className={classes.join(' ')}>
        <button className="collapse__btn" onClick={this.handleToggle}>
          <Icon name="Right" className="collapse__icon" viewBox="4 4 14 14"/>
          {title}
        </button>
        <div className={'collapse__content'}>
          {isOpened && children}
        </div>
      </div>
    );
  }
}

Collapse.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
