import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import '../scss/_switch.scss';
import { isEqual } from '../utils/compare';

export class Switch extends Component {
  constructor(props) {
    super(props);

    this.switchItems = [];
    this.switchItemsWidths = [];
    this.size = props.size;
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    return !(isEqual([
      'list',
      'className',
      'value',
      'name',
      'size',
    ], this.props, nextProps));
  }

  componentDidMount() {
    this.getItemsWidths();
    this.forceUpdate();
    this.onFontLoaded();
  }

  componentDidUpdate() {
    if (this.size !== this.props.size) {
      this.size = this.props.size;
      this.updateStyle();
    }
  }

  onFontLoaded = () => {
    const font = new FontFaceObserver('Lato Regular');

    font.load().then(() => {
      this.updateStyle();
    });
  }

  updateStyle = () => {
    this.getItemsWidths();
    const style = this._getIndicatorStyle();
    this.indicator.style.left = style.left;
    this.indicator.style.width = style.width;
  }

  _pushSwitchItemRef(item) {
    if (item && this.switchItems.indexOf(item) === -1) {
      this.switchItems.push(item);
    }
  }

  _setIndicatorRef(item) {
    if (!this.indicator) {
      this.indicator = item;
    }
  }

  _getIndicatorStyle = () => {
    const checked = this.props.value;

    const [first, second] = this.switchItemsWidths;
    return {
      left: `${checked ? (first - 1) : -1}px`,
      width: `${checked ? second + 2 : first + 2}px`,
    };
  };

  getItemsWidths() {
    this.switchItemsWidths = [];
    this.switchItems.forEach((item) => {
      this.switchItemsWidths.push(item.offsetWidth);
    });
  }

  handleOnChange({ target }) {
    const value = target.checked;
    this.props.onChange(value);
  }

  render() {
    const {
      size,
      className = '',
      list,
      name,
      value = false,
    } = this.props;

    return (
      <div className={`switch ${className} ${size ? `_${size}` : ''}`} ref={el => { this.switch = el; }}>
        <input
          className="switch__input"
          type="checkbox"
          name={name}
          id={name}
          checked={value}
          onChange={(e) => this.handleOnChange(e)}
        />
        <label
          className="switch__label"
          htmlFor={name}
        >
          {list.map((item, i) => {
            return (
              <span
                key={i}
                className={`switch__item ${(value === Boolean(i)) ? '_active' : ''}`}
                ref={(item) => this._pushSwitchItemRef(item)}
              >
                {item}
              </span>
            )
          })}
          <div
            className="switch__indicator"
            ref={(item) => this._setIndicatorRef(item)}
            style={this._getIndicatorStyle()}
          />
        </label>
      </div>
    );
  }
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
};
