import React from 'react';

const Tumbler = ({ isActive, label, onClick }) => {
  return (
    <div onClick={onClick} className={`select__tumbler ${isActive ? '_active' : ''}`}>
      <div className='select__tumbler-slide'>
        <div className='select__tumbler-btn'></div>
      </div>
      <div className='select__tumbler-label'>
        {label || 'Shows Groups'}
      </div>
    </div>
  )
}
export default Tumbler;