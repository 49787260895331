import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withClickOutside } from '../../hoc/clickOutside';

class DatesPickerOnDoneWrapperComponent extends React.PureComponent {
  static propTypes = {
    onSelect: PropTypes.func,
    value: PropTypes.any,
    resetInputValue: PropTypes.func,
    updateByDone: PropTypes.bool,
    preselectedRangeValues: PropTypes.array,
  };

  state = {
    value: null,
  };

  findPreselectedRangeId = (dateRange, preselectedRange = []) => {
    const start = moment(dateRange.start);
    const end = moment(dateRange.end);

    for (let ii = 0; ii < preselectedRange.length; ii++) {
      const current = preselectedRange[ii];
      const rangeStart = moment(current.start);
      const rangeEnd = moment(current.end);
      if (start.isSame(rangeStart, 'day') && end.isSame(rangeEnd, 'day')) {
        return current.key;
      }
    }

    return null;
  }

  onSelect = (value, states, preselectedRangeKey) => {
    const { onSelect, preselectedRangeValues, updateByDone, resetInputValue } = this.props;
    const formatedPreselectedKey = preselectedRangeKey || this.findPreselectedRangeId(value, preselectedRangeValues);
    this.setState({
      value,
      states,
      preselectedRange: formatedPreselectedKey,
    });
    resetInputValue();
    if (!updateByDone) {
      onSelect(value, states, formatedPreselectedKey);
    }
  };

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  handleClickOutside = () => this.props.resetInputValue();

  render() {
    const { updateByDone, onSelect } = this.props;
    const { value, preselectedRange } = this.state;
    return this.props.children(value, updateByDone ? this.onSelect : onSelect, preselectedRange);
  }
}

export const DatesPickerOnDoneWrapper = withClickOutside(DatesPickerOnDoneWrapperComponent);
