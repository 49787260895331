import React from 'react';
import PropTypes from 'prop-types';

export const ContextMenuList = ({className = '', children, ...rest}) => {
  return (
    <ul className={`context-menu__list ${className}`} {...rest}>
      {children}
    </ul>
  );
};

ContextMenuList.propTypes = {
  className: PropTypes.string,
};
