import PropTypes from 'prop-types';

export const tablePropTypes = {
  paging: PropTypes.shape({
    activePage: PropTypes.number.isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    itemsCountPerPage: PropTypes.oneOf([10, 25, 50, 100]).isRequired,
    pageRangeDisplayed: PropTypes.number,
  }),
  body: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.number]),
    hover: PropTypes.func,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    classNameHover: PropTypes.string,
  })),
  header: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.elementType,
    ]),
    className: PropTypes.string,
    sortingKey: PropTypes.string,
    compareFunc: PropTypes.func,
    draggableGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  freeze: PropTypes.shape({
    rows: PropTypes.number,
    columns: PropTypes.number,
  }),
  sorting: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
    compareFunc: PropTypes.func,
  }),
};
