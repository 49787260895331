import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../scss/_pagination.scss';
import { Icon } from './Icon';

export class Pagination extends PureComponent {
  handleOnChange = (e, pageNumber) => {
    e.preventDefault();

    const {
      onChange,
    } = this.props;

    onChange && onChange(pageNumber);
  };

  get pagesLength() {
    const {
      itemsCountPerPage,
      totalItemsCount,
    } = this.props;

    return Math.ceil(totalItemsCount / itemsCountPerPage);
  }

  get hasPrev() {
    const {
      activePage,
    } = this.props;

    return activePage > 1;
  }

  get hasNext() {
    const {
      activePage,
    } = this.props;

    return activePage < this.pagesLength;
  }

  getRange() {
    const {
      pageRangeDisplayed,
      activePage,
    } = this.props;

    const pagesLength = this.pagesLength;
    const middlePages = Math.floor(pageRangeDisplayed / 2);

    let startShift = 0;
    let endShift = 0;

    let start;
    let end;
    if (pagesLength <= pageRangeDisplayed) {
      start = 1;
      end = pagesLength;
    } else {
      start = Math.max(activePage - middlePages, 1);
      if (activePage - middlePages < 1) {
        endShift += Math.abs(activePage - middlePages) + 1;
      }
      end = Math.min(activePage + middlePages, pagesLength);
      if (activePage + middlePages > pagesLength) {
        startShift -= Math.abs((activePage + middlePages) - pagesLength);
      }
    }



    const pages = [];

    for (let ii = start + startShift; ii <= end + endShift; ii++) {
      pages.push(ii);
    }

    return pages;
  }

  handlePrev = (e) => {
    e.preventDefault();
    this.hasPrev && this.handleOnChange(e, 1);
  };

  handleNext = (e) => {
    e.preventDefault();
    this.hasNext && this.handleOnChange(e, this.pagesLength);
  };

  renderPages() {
    const {
      activePage,
    } = this.props;

    const pages = this.getRange();

    return pages.map(number => {
      const active = number === activePage;
      return (
        <li key={number} className={`pagination__item ${active ? 'pagination__item_active' : ''}`}>
          {active ?
            <span className="pagination__link">{number}</span> :
            <a href='/' onClick={(e) => this.handleOnChange(e, number)} className="pagination__link">{number}</a>
          }
        </li>
      );
    })
  }

  render() {
    return (
      <div className="pagination">
        <ul className="pagination__list">
          <li className={`pagination__item ${!this.hasPrev ? 'pagination__item_disabled' : ''}`}>
            <a
              className="pagination__link pagination__link_control"
              href="/"
              onClick={this.handlePrev}
            >
              <Icon name="Left"/>
            </a>
          </li>
          {this.renderPages()}
          <li className={`pagination__item ${!this.hasNext ? 'pagination__item_disabled' : ''}`}>
            <a
              className="pagination__link pagination__link_control"
              href="/"
              onClick={this.handleNext}
            >
              <Icon name="Right"/>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

Pagination.defaultProps = {
  itemsCountPerPage: 10,
  activePage: 1,
  pageRangeDisplayed: 5,
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageRangeDisplayed: PropTypes.number,
};