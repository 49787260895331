import React, { Component } from 'react';
import { Icon } from './Icon';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import '../scss/_text-field.scss';

export class TextField extends Component {
  static defaultProps = {
    type: 'text',
    onValidate: () => {
    },
    validateOnInit: false,
    svgParams: {},
  };

  constructor(props) {
    super();

    this.state = {
      isValid: true,
      error: null,
      isFilled: !!props.value,
    };

    this.isControlled = props.value !== undefined;
  }

  componentDidMount() {
    if (!this.isControlled) {
      this.checkDirty(this.inputRef);
    }

    if (this.props.validateOnInit) {
      this.validateControl();
    }

    this.props.inputRef && this.props.inputRef(this.inputRef);
  }

  validateControl() {
    const target = this.inputRef;
    return this.validate(target.value, target);
  }

  checkDirty({ value }) {
    if (!!value) {
      this.setState({
        isFilled: true,
      });
    } else {
      this.setState({
        isFilled: false,
      });
    }
  }

  handleValueChange(value, target) {
    const {
      type,
      onChange,
      validationRules
    } = this.props;

    if (type === 'amount') {
      let i = 0;
      value = value
        .replace(/[^0-9.]+/g, '')
        .replace(/\./g, (match) => {
          i += 1;
          return (i === 2) ? '' : match;
        });
    }

    if (onChange) {
      let errors;
      if (validationRules && validationRules.length) {
        errors = validationRules.reduce((errors, rule) => {
          if (!rule.func(value, target, rule)) {
            if (!errors) {
              errors = [];
            }
            errors.push({
              name: target.name,
              message: rule.error(target.name, rule)
            });
          }
          return errors;
        }, null);
      }
      onChange(value, errors);
    }
    if (!this.isControlled) {
      this.checkDirty({ value });
    }

    this.validate(value, target);
  }

  componentDidUpdate(oldProps) {
    if ((this.props.value !== oldProps.value) ||
      (!isEqual(this.props.validationKeys, oldProps.validationKeys))) {
      if (this.isControlled) {
        this.checkDirty({ value: this.props.value });
      }

      this.validate(this.props.value, this.inputRef);
    }
  }

  validate(value, target) {
    const {
      validationRules = [],
    } = this.props;
    const rules = validationRules.slice();

    let rule = rules.pop();

    while (rule) {
      const isValid = rule.func(value, target, rule);
      if (!isValid) {
        this.setValidationState(false, rule, target.name);
        return false;
      }
      rule = rules.pop();
    }

    this.setValidationState(true);
    return true;
  }

  setValidationState(isValid, rule, name) {
    if (isValid) {
      this.setState({
        isValid: true,
        error: null,
      });
      this.props.onValidate(true);
    } else {
      this.setState({
        isValid: false,
        error: rule.error(name, rule),
      });
      this.props.onValidate(false);
    }
  }

  handleFocus = (event) => {
    this.setState({ focused: true });
    this.inputRef.select();
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleBlur = (event) => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleKeyUp = (event) => {
    if (this.props.onSubmitted && event.key === 'Enter') {
      this.props.onSubmitted(this.props.value);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual([
      'label',
      'type',
      'name',
      'value',
      'helperText',
      'placeholder',
      'disabled',
      'validationKeys',
      'isTextarea',
      'withBorder',
      'iconName',
      'svgParams',
    ], this.props, nextProps) && isEqual([
      'focused',
      'isValid',
      'error',
      'isFilled',
    ], this.state, nextState));
  }

  pushClassName = (arrays) => (className) => {
    arrays.forEach(array => array.push(className));
  }

  handleResetClick = () => {
    this.props.onChange('');
    this.setState({ isFilled: false });
  }

  render() {
    const {
      className = '',
      label,
      type,
      name,
      value,
      helperText,
      placeholder,
      disabled,
      isTextarea,
      withBorder,
      iconName,
      svgParams,
    } = this.props;

    const {
      focused,
      isValid,
      error,
      isFilled,
    } = this.state;

    const textFieldClassName = ['text-field', className];
    const labelClassName = ['text-field__label'];
    const baseClassName = ['text-field__base'];
    const inputClassName = ['text-field__input'];

    const pushClassName = this.pushClassName([
      textFieldClassName,
      labelClassName,
      baseClassName,
      inputClassName,
    ]);

    if (focused) {
      pushClassName('_focused');
    }
    if (isFilled) {
      pushClassName('_filled');
    }
    if (withBorder) {
      pushClassName('_with-border');

      if (iconName) {
        pushClassName('_icon');
      }
    }
    if (!isValid) {
      pushClassName('_error');
    }
    if (disabled) {
      pushClassName('_disabled');
    }

    const placeholderContainer = (!isFilled && focused) ? placeholder : '';

    const reset = iconName === 'Search';

    return (
      <div className={textFieldClassName.join(' ')}>
        <label className={labelClassName.join(' ')}>
          {label}
        </label>
        <div className={baseClassName.join(' ')}>
          {withBorder && iconName &&
            <i className="text-field__icon">
              <Icon name={iconName} {...svgParams} />
            </i>
          }
          {React.createElement(
            !isTextarea ? 'input' : 'textarea',
            {
              ref: el => { this.inputRef = el; },
              className: inputClassName.join(' '),
              type: type,
              name: name,
              value: value,
              placeholder: placeholderContainer,
              onFocus: this.handleFocus,
              onBlur: this.handleBlur,
              onChange: (e) => this.handleValueChange(e.target.value, e.target),
              onKeyUp: this.handleKeyUp,
            }
          )}
          {withBorder && reset && value &&
            <button
              className="text-field__reset"
              onClick={this.handleResetClick}
            >
              <Icon name="Close" />
            </button>
          }
        </div>
        {(error || helperText) &&
          <p className="text-field__help-text">{error || helperText}</p>
        }
      </div>
    );
  }
}

TextField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  validationRules: PropTypes.array,
  validateOnInit: PropTypes.bool,
  validationKeys: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
  onSubmitted: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  isTextarea: PropTypes.bool,
  withBorder: PropTypes.bool,
  iconName: PropTypes.string,
  svgParams: PropTypes.object,
};

export function createTextField(container, options) {
  class TextField {
    container;
    wrapper;
    label;
    base;
    input;
    helper;

    constructor(container, options = {}) {
      this.container = container;
      this.options = options;

      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.init();

      container.appendChild(this.wrapper);

      return this;
    }

    init() {
      this.createMarkup();
      this.addListeners();
    }

    createMarkup() {
      this.wrapper = document.createElement('div');
      this.wrapper.classList.add('text-field');

      this.label = document.createElement('label');
      this.label.classList.add('text-field__label');
      this.label.innerText = this.options.label || '';

      this.base = document.createElement('div');
      this.base.classList.add('text-field__base');

      this.input = document.createElement('input');
      this.input.classList.add('text-field__input');
      this.input.type = this.options.type || 'text';
      this.input.name = this.options.name || '';

      this.base.appendChild(this.input);

      this.wrapper.appendChild(this.label);
      this.wrapper.appendChild(this.base);

      this.updateHelperElement(this.options.helperText);
    }

    addListeners() {
      this.input.addEventListener('focus', this.handleFocus);
      this.input.addEventListener('blur', this.handleBlur);
      this.input.addEventListener('keyup', this.handleChange);
    }

    updateHelperElement(errorText) {
      if (this.options.helperText || errorText) {
        if (!this.helper) {
          this.helper = document.createElement('p');
          this.helper.classList.add('text-field__help-text');
          this.wrapper.appendChild(this.helper);
        }

        this.helper.innerText = errorText || this.options.helperText;
      }
    }

    handleFocus() {
      this.base.classList.add('_focused');
      this.label.classList.add('_focused');
    }

    handleBlur() {
      this.base.classList.remove('_focused');
      this.label.classList.remove('_focused');
    }

    handleChange(e) {
      const { value } = e.target;

      if (value) {
        this.label.classList.add('_filled');
      } else {
        this.label.classList.remove('_filled');
      }

      this.validate(value, e.target)
    }

    validate(value, target) {
      const {
        validationRules = [],
      } = this.options;
      const rules = validationRules.slice();

      let rule = rules.pop();

      while (rule) {
        const isValid = rule.func(value, target, rule);
        if (!isValid) {
          this.setValidationState(false, rule, target.name);
          return;
        }
        rule = rules.pop();
      }

      this.setValidationState(true)
    }

    setValidationState(isValid, rule, name) {
      if (isValid) {
        this.wrapper.classList.remove('_error');
        this.base.classList.remove('_error');
        this.updateHelperElement();
      } else {
        this.wrapper.classList.add('_error');
        this.base.classList.add('_error');
        this.updateHelperElement(rule.error(name, rule));
      }
    }
  }

  return new TextField(container, options);
}
