import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { TextField } from './TextField';
import { Icon } from './Icon';
import { Dialog, DialogHeader, DialogContent, DialogFooter } from './Dialog';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import '../scss/_editable-text.scss';

const ActionButtons = ({ onClose, onConfirm, useRemember, remember, onChange, actionsHelp }) => {
  const buttons = (
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex">
        {actionsHelp}
      </div>
      <div className="d-flex align-items-center">
        <Button className="btn-square _conflower-blue mr-2" onClick={onClose}>
          Cancel
        </Button>
        <Button autoFocus={true} className="btn-square _filled _conflower-blue" onClick={onConfirm}>
          Apply
        </Button>
      </div>
    </div>
  );
  return (
    useRemember
      ? <div className="d-flex justify-content-between">
        {useRemember && <Checkbox
          className="mr-3"
          label="Don't ask me again"
          checked={remember}
          onChange={onChange}
        />}
        {buttons}
      </div>
      : buttons
  );
};

export class EditableText extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultValue: (props.value || '').toString(),
      value: (props.value || '').toString(),
      showPopup: false,
      remember: false,
      type: props.type || 'text'
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        defaultValue: this.props.value,
        value: this.props.value,
      });
    }
  }

  handleChange = (value) => {
    const {
      type
    } = this.state;

    if (type === 'amount') {
      let i = 0;
      value = value
        .replace(/[^0-9.]+/g, '')
        .replace(/\./g, function (match) {
          i++;
          return (i === 2) ? '' : match;
        });
    } else if (type === 'date') {
      let i = 0;
      value = value
        .replace(/[^0-9/]+/g, '')
        .replace(/\//g, function (match) {
          i++;
          return (i === 3) ? '' : match;
        });
    }

    this.setState({
      value: this.props.onChange ? this.props.onChange(value) : value,
    });
  };

  handleBlur = () => {
    const {
      defaultValue,
      value,
    } = this.state;

    if (value.trim() && defaultValue !== value) {
      this.handleShowPopup();
    } else {
      this.setState({ value: defaultValue });
    }
  };

  handleSubmit = (e) => {
    const {
      defaultValue,
      value,
      type,
    } = this.state;

    e.preventDefault();

    if (!value.trim() || defaultValue === value) {
      return;
    }

    if (type === 'date') {
      const dateRegex = /\d{1,2}\/\d{1,2}\/20\d{2}/g;
      if (value !== defaultValue && dateRegex.test(value) &&
        (this.props.isValid ? this.props.isValid(value, defaultValue) : true)) {
        this.handleShowPopup();
      }
    } else {
      this.handleShowPopup();
    }
  };

  handleShowPopup = () => {
    const { Popup: { remember, storageKey } } = this.props;

    if (remember && JSON.parse(window.localStorage.getItem(storageKey))) {
      this.onConfirm();
      return;
    }

    this.setState({
      showPopup: true,
    });
  };

  handleClosePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  handleCancel = () => {
    this.onCancel();
  };

  handleConfirm = () => {
    if (this.state.remember && this.props.Popup.storageKey) {
      window.localStorage.setItem(this.props.Popup.storageKey, JSON.stringify(true));
    }
    this.onConfirm();
  };

  onCancel = () => {
    const { defaultValue } = this.state;
    this.setState({
      value: defaultValue,
    }, this.handleClosePopup);
    this.props.cancel && this.props.cancel(this.state.defaultValue);
  };

  onConfirm = () => {
    if (this.props.confirm) {
      const { value: oldValue } = this.state;
      this.setState({
        value: this.props.value,
      }, () => this.props.confirm(oldValue, this.state.defaultValue));
    }
    this.handleClosePopup();
  };

  handleChangeRemember = (value) => {
    this.setState({
      remember: value,
    });
  };

  renderPopup = () => {
    const { Popup: { title, actions, remember, actionsHelp, modalProps } } = this.props;
    const { defaultValue, value } = this.state;
    return createPortal(
      <Dialog
        open={true}
        onClickOutside={this.handleCancel}
        modalProps={modalProps}
      >
        <DialogHeader>
          <h5 className="title-card">{title}</h5>
        </DialogHeader>
        <DialogContent>
          {isFunction(this.props.children) ? this.props.children(defaultValue, value) : this.props.children}
        </DialogContent>
        <DialogFooter>
          {(actions && actions()) ||
          <ActionButtons
            onChange={this.handleChangeRemember}
            useRemember={remember}
            remember={this.state.remember}
            onClose={this.handleCancel} onConfirm={this.handleConfirm}
            actionsHelp={actionsHelp}
          />
          }
        </DialogFooter>
      </Dialog>
      , this.props.Popup.appendTo || document.body);
  };

  render() {
    const { value: propsValue } = this.props;
    const { value, showPopup } = this.state;

    const iconEl = (value.trim() && `${propsValue}` !== `${value}`)
      ? <Icon name="Done" />
      : null;

    return (
      <form
        className={`editable-text ${this.props.className || ''}`}
        onSubmit={this.handleSubmit}
        ref={() => {
          this.props.innerRef && this.props.innerRef(this);
        }}
      >
        <TextField
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          inputRef={el => {
            this.props.inputRef && this.props.inputRef(el);
          }}
        />
        {iconEl}
        {showPopup && this.renderPopup()}
      </form>
    );
  }
}

EditableText.defaultProps = {
  confirm: () => {
  },
  cancel: () => {
  },
  Popup: {
    title: '',
    remember: false,
    storageKey: '',
  },
};

EditableText.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  Popup: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    remember: PropTypes.bool,
    storageKey: PropTypes.string,
    appendTo: PropTypes.instanceOf(window.HTMLElement),
    actions: PropTypes.func,
    actionsHelp: PropTypes.element,
    modalProps: PropTypes.object,
  }),
  innerRef: PropTypes.func,
  onChange: PropTypes.func,
  isValid: PropTypes.func
};
