import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withClickOutside } from '../../hoc/clickOutside';
import { ContextMenu } from '../ContextMenu';
import { ContextMenuList } from '../contextMenu/ContextMenuList';
import { ContextMenuListDivider } from '../contextMenu/ContextMenuListDivider';
import { ContextMenuListItem } from '../contextMenu/ContextMenuListItem';

class TableContextMenuComponent extends PureComponent {
  handleClickOutside() {
    this.props.onClose();
  }

  handleFreezeRows = (number) => {
    const { row } = this.props;
    this.props.onFreezeRow(row, number);
  };

  handleFreezeColumns = (number) => {
    const { column } = this.props;
    this.props.onFreezeColumn(column, number);
  };

  render() {
    const {
      rowNumber,
      colNumber,
      unsortedDataLength = 0,
      hasTableCheckboxColumn = false, // if true: 'freeze 1 Column' means freeze 2 Columns (ignores checkbox column)
    } = this.props;

    const rowNumberDisplay = rowNumber + 1; // starts from 0
    const colNumberDisplay = colNumber + 1; // starts from 0

    // correction of freeze columns real number
    const freezeColumnsCorrection = hasTableCheckboxColumn ? 1 : 0;

    return (
      <ContextMenu
        clientX={this.props.clientX}
        clientY={this.props.clientY}
        onClose={this.props.onClose}
      >
        <ContextMenuList>
          <ContextMenuListItem title={true}>
            Freeze
          </ContextMenuListItem>
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeRows(0)}
            >
              No rows
            </button>
          </ContextMenuListItem>
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeRows(1 + unsortedDataLength)}
            >
              1 Row
            </button>
          </ContextMenuListItem>
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeRows(2 + unsortedDataLength)}
            >
              2 Rows
            </button>
          </ContextMenuListItem>
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeRows((rowNumberDisplay <= unsortedDataLength ? unsortedDataLength + 1 : rowNumberDisplay))}
            >
              Up to Current Row ({rowNumberDisplay - unsortedDataLength > 0 ? rowNumberDisplay - unsortedDataLength : 1})
            </button>
          </ContextMenuListItem>
          <ContextMenuListDivider />
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeColumns(0)}
            >
              No Columns
            </button>
          </ContextMenuListItem>
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeColumns(1 + freezeColumnsCorrection)}
            >
              1 Column
            </button>
          </ContextMenuListItem>
          <ContextMenuListItem className="_with-btn">
            <button
              className="context-menu__button"
              onClick={() => this.handleFreezeColumns(2 + freezeColumnsCorrection)}
            >
              2 Columns
            </button>
          </ContextMenuListItem>
          {colNumberDisplay - freezeColumnsCorrection > 0 &&
            <ContextMenuListItem className="_with-btn">
              <button
                className="context-menu__button"
                onClick={() => this.handleFreezeColumns(colNumberDisplay)}
              >
                Up to Current Column ({colNumberDisplay - freezeColumnsCorrection})
              </button>
            </ContextMenuListItem>
          }
        </ContextMenuList>
      </ContextMenu>
    );
  }
}

export const TableContextMenu = withClickOutside(TableContextMenuComponent);

TableContextMenuComponent.propTypes = {
  row: PropTypes.object,
  rowNumber: PropTypes.number,
  colNumber: PropTypes.number,
  clientX: PropTypes.number,
  clientY: PropTypes.number,
  onClose: PropTypes.func,
  onFreezeRow: PropTypes.func,
  onFreezeColumn: PropTypes.func,
  unsortedDataLength: PropTypes.number,
  hasTableCheckboxColumn: PropTypes.bool,
};
