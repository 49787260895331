import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_tumbler.scss';

export class Tumbler extends Component {
  render() {
    const {
      onOff = false,
      name,
      id,
      className,
      on = false,
      onChange,
      disabled,
      title,
    } = this.props;

    return (
      <div className={`tumbler ${disabled ? '_disabled' : ''} ${onOff ? '_on-off' : ''} ${on ? '_on' : ''} ${className || ''}`}>
        <input
          style={{ display: 'none' }}
          type="checkbox"
          name={name}
          id={id}
          checked={on}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={id} className="tumbler__label">
          <span className="tumbler__btn">
            {onOff && <React.Fragment>
              <span className="tumbler__bg">
                <i>off</i>
                <i>on</i>
              </span>
              <span className="tumbler__control">
                <i>off</i>
                <i>on</i>
              </span>
            </React.Fragment>}
          </span>

          {title &&
            <span className="tumbler__title">
              {title}
            </span>
          }
        </label>
      </div>
    )
  }
}

Tumbler.propTypes = {
  onOff: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  on: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};
