import React, { Component } from 'react';
import { Icon } from '../Icon';
import { SORT_DIRECTION } from './tableHelpers';
import { tablePropTypes } from './PropTypes';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { isEqual as _isEqual } from '../../utils/compare';

export class TableHeaderRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDragIndex: null,
      endDragIndex: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      isEqual(nextProps.header, this.props.header) &&
      nextProps.sorting === this.props.sorting &&
      nextProps.hoveredColIndex === this.props.hoveredColIndex &&
      _isEqual(['startDragIndex', 'endDragIndex'], this.state, nextState)
    );
  }

  _startDragIndex = null;
  _endDragIndex = null;
  _dragItem = null;

  handleDragStart = (item, index) => (e) => {
    this._startDragIndex = index;
    this._dragItem = item;
    this.setState({
      startDragIndex: index,
      draggableGroup: item.draggableGroup,
    });
  };

  handleDragOver = (item, index) => (e) => {
    if (this._endDragIndex === index) {
      return;
    }
    if (this._dragItem && this._dragItem.draggableGroup) {
      if (this._startDragIndex !== index && this._dragItem.draggableGroup === item.draggableGroup) {
        this._endDragIndex = index;
        this.setState({
          endDragIndex: index,
        });
      }
    } else if (this._startDragIndex !== index && this._dragItem && !item.draggableGroup) {
      this._endDragIndex = index;
      this.setState({
        endDragIndex: index,
      });
    }
  };

  handleDragEnd = () => {
    this.props.onDragEnd(this._dragItem, {
      start: this._startDragIndex,
      end: this._endDragIndex,
    });
    this._startDragIndex = null;
    this._endDragIndex = null;
    this._dragItem = null;
    this.setState({
      startDragIndex: null,
      endDragIndex: null,
      draggableGroup: null,
    });
  };

  render() {
    const {
      header,
      sorting,
      onSortingChanged,
      draggable,
      hoveredColIndex,
      theadRowHeight,
      handleTheadOnMouseEnterLeave,
      freezeColumns,
    } = this.props;

    const {
      startDragIndex,
      endDragIndex,
      draggableGroup
    } = this.state;

    if (!header || !header.length) {
      return null;
    }

    return (
      <tr>
        {header.map((h, i) => {
          const {
            className,
            label,
            tooltip,
          } = h;

          const hasSort = sorting && h.sorting !== false && h.sortingKey;

          let sortIcon;
          let attrs = {};
          if (hasSort) {
            attrs.onClick = () => onSortingChanged(h);
            if (h.sortingKey !== sorting.field) {
              sortIcon = <Icon className="sort-icon_default" name="UnfoldMore" />;
            } else if (sorting.direction === SORT_DIRECTION.ASC) {
              sortIcon = <Icon className="sort-icon_default" name="ArrowUp" />;
            } else {
              sortIcon = <Icon className="sort-icon_default" name="ArrowDown" />;
            }
          }

          const classNames = [className || ''];
          if (hasSort) {
            classNames.push('_sorting');
          }

          if (draggable && h.draggable !== false) {
            attrs.draggable = true;
            attrs.onDragStart = this.handleDragStart(h, i);
            attrs.onDragOver = this.handleDragOver(h, i);
            attrs.onDragEnd = this.handleDragEnd;

            if (endDragIndex === i) {
              classNames.push('_separator');
            }

            if (startDragIndex === i) {
              classNames.push('_hide');
            }

            if ((draggableGroup && h.draggableGroup !== draggableGroup) || (this._dragItem && !draggableGroup && h.draggableGroup)) {
              classNames.push('_hide');
            }

            classNames.push(startDragIndex < endDragIndex ? '_right' : '_left');
          }

          if (hoveredColIndex === i) {
            classNames.push('_th-hover');
          }

          const sortingClassName = (h.sortingKey !== sorting.field) ? '' : ((sorting.direction === SORT_DIRECTION.ASC) ? '_up' : '_down');

          return (
            <td
              className={classNames.join(' ')}
              {...attrs}
              style={{ height: `${theadRowHeight}px` }}
              key={i}
              onMouseEnter={tooltip ? handleTheadOnMouseEnterLeave({i: i + freezeColumns, text: tooltip}) : null}
              onMouseLeave={tooltip ? handleTheadOnMouseEnterLeave() : null}
            >
              <div className="td-content" style={{ height: `${theadRowHeight}px` }}>
                {label}
                {sortIcon &&
                  <div className={`td-sorting ${sortingClassName}`}>
                    {sortIcon}
                  </div>
                }
              </div>
            </td>
          );
        })}
      </tr>
    );
  }
}

TableHeaderRow.propTypes = {
  header: tablePropTypes.header,
  freeze: tablePropTypes.freeze,
  sorting: tablePropTypes.sorting,
  onSortingChanged: PropTypes.func,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  theadRowHeight: PropTypes.number,
  hoveredColIndex: PropTypes.number,
  handleTheadOnMouseEnterLeave: PropTypes.func,
  freezeColumns: PropTypes.number,
};
