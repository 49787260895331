import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_navbar.scss';
import { ButtonCircle } from './Button';
import { Logo } from './Logo';
import { Tooltip } from './Tooltip';
import { isEqual } from '../utils/compare';

const DefaultLogo = () => {
  return (
    <div className="nav-bar__circle-icon">
      <span />
      <span />
      <span />
      <span />
    </div>
  );
};

const DefaultBack = ({ ...rest }) => {
  return (
    <ButtonCircle {...rest} outline={true} iconName="ArrowLeft"/>
  );
};

export class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual([
      'backButton',
      'className',
      'title',
      'leftActions',
      'rightActions',
      'leftLogo',
      'profileLogo',
      'profileAvatar',
    ], this.props, nextProps) && isEqual([
      'open',
    ], this.state, nextState));
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideProfile);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideProfile);
  }

  preventOutsideClick = false;

  handleClickOutsideProfile = (e) => {
    if (this.profile && !this.profile.contains(e.target) && this.state.open && !this.preventOutsideClick) {
      this.togglePopup();
    }
    this.preventOutsideClick = false;
  };

  togglePopup = (preventOutside = false) => {
    const {
      open,
    } = this.state;

    this.setState({
      open: !open,
    });

    this.preventOutsideClick = preventOutside;
  };

  render() {
    const {
      className,
      backButton,
      backHandler,
      title,
      leftLogo,
      profileLogo,
      profileAvatar,
      popupContent,
      isBeta,
      children,
    } = this.props;

    let backComponent = null;
    if (backButton) {
      if (React.isValidElement(backButton)) {
        backComponent = backButton;
      } else {
        backComponent = <DefaultBack onClick={backHandler}/>;
      }
    }
    const {
      open,
    } = this.state;
    return (
      <div className={`nav-bar ${className}`}>
        <div className="nav-bar__container">
          {backComponent}
          {leftLogo}
          <h5 className="nav-bar__title">
            {title}
            {isBeta && (
              <span className="nav-bar__beta">
                beta
              </span>
            )}
          </h5>
          <div className="nav-bar__content">
            {children}
          </div>
          <div className="nav-bar__right">
            <div
              ref={(el) => this.profile = el}
              className={`nav-bar__profile ${open ? '_focus' : ''}`}
            >
              <Tooltip label="Account Info" position="bottom" auto={false}>
                <div className="nav-bar__profile-container" onClick={this.togglePopup}>
                  {profileLogo}
                  {profileAvatar}
                </div>
              </Tooltip>
              <div className={`nav-bar__popup ${open ? '_show' : ''}`}>
                {popupContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NavigationBar.defaultProps = {
  backButton: true,
  backHandler: () => {
  },
  className: '',
  leftLogo: <DefaultLogo />,
  profileLogo: <Logo animate={false} className='nav-bar__profile-logo' />,
  profileAvatar: <div className="nav-bar__profile-avatar" />,
  popupContent: null,
  isBeta: false,
};

NavigationBar.propTypes = {
  className: PropTypes.string,
  backButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  backHandler: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  leftLogo: PropTypes.element,
  profileLogo: PropTypes.element,
  profileAvatar: PropTypes.element,
  popupContent: PropTypes.element,
  isBeta: PropTypes.bool,
};
