import React, { Component } from 'react';
import { Tooltip } from '../Tooltip';
import PropTypes from 'prop-types';
import { isEqual as _isEqual } from '../../utils/compare';

const TdInner = ({children, tooltip, isUnsorted, tbodyRowHeight}) => {
  const tdContentProps = {
    style: { height: `${tbodyRowHeight}px` },
    className: 'td-content',
  }

  return (tooltip && !isUnsorted) ? (
    <Tooltip {...tdContentProps} label={tooltip}>
      {children}
    </Tooltip>
  ) : (
    <div {...tdContentProps}>
      {children}
    </div>
  );
}

export class TableCell extends Component {
  state = {
    isTdClicked: false,
    hasTitle: undefined,
  }

  shouldComponentUpdate(nextProps, nextState) {
    let isTotalCheckboxEqual = true;
    if (this.props.data.total) {
      isTotalCheckboxEqual = _isEqual([
        'isAllSelected',
        'isSomeSelected',
      ], this.props, nextProps);
    }

    return !(
      _isEqual([
        'field',
        'data',
        'colIndex',
        'onContextMenu',
        'rowIndex',
        'freezeColumns',
        'tdOnMouseOver',
        'tdOnMouseLeave',
        'isSkeleton',
        'tbodyRowHeight',
      ], this.props, nextProps) &&
      _isEqual(['isTdClicked', 'hasTitle'], this.state, nextState) &&
      isTotalCheckboxEqual
    );
  }

  handleTdClick = () => {
    if (this.props.field.click && !this.state.isTdClicked) {
      this.setState(
        {isTdClicked: true},
        () => {
          window.addEventListener('click', this.handleWindowClick);
          window.addEventListener('keydown', this.handleKeyDown);
          this.innerClickElement && this.innerClickElement.focus();
        }
      );
    }
  }

  handleWindowClick = ({target}) => {
    if (!(target === this.td || this.td.contains(target))) {
      this.deactivateCell();
    }
  }

  deactivateCell = () => {
    window.removeEventListener('click', this.handleWindowClick);
    window.removeEventListener('keydown', this.handleKeyDown);
    this.setState({isTdClicked: false});
  }

  handleKeyDown = (e) => {
    if (e.key === 'Escape' || e.key === 'Enter') {
      this.deactivateCell();
    }
  }

  handleMouseEnter = () => {
    this.setTitle();
  }

  setTitle = () => {
    if (this.tdElement && (this.tdElement.offsetWidth < this.tdElement.scrollWidth)) {
      !this.state.hasTitle && this.setState({ hasTitle: true });
    } else {
      this.state.hasTitle && this.setState({ hasTitle: false });
    }
  }

  render() {
    const {
      field,
      data,
      colIndex,
      onContextMenu,
      rowIndex,
      freezeColumns,
      tdOnMouseOver,
      tdOnMouseLeave,
      isSkeleton,
      tbodyRowHeight,
    } = this.props;

    const {
      isTdClicked,
      hasTitle,
    } = this.state;

    const classNames = [];

    if (field.className) {
      classNames.push(typeof field.className === 'function' ? field.className(data, field) : field.className);
    }
    if (isTdClicked) {
      classNames.push('_clicked');
    }

    const isUnsorted = data.rowClassName && (data.rowClassName.indexOf('tr-unsorted') !== -1);

    let childrenProps = {};
    let clickElement;
    if (field.click) {
      clickElement = field.click(data, field);

      if (clickElement && field.isFocusOnClick) {
        const refName = field.clickFocusRefPropName || 'ref';
        childrenProps = { [refName]: el => { this.innerClickElement = el; } };
      }
    }

    let tdContent;
    if (!isSkeleton) {
      tdContent = typeof field.key === 'function' ? field.key(data, field) : data[field.key];
    }

    let tdProps = {
      className: classNames.join(' '),
      style: { height: `${tbodyRowHeight}px` },
    };
    if (!isSkeleton) {
      tdProps = {
        ...tdProps,
        ref: el => { this.td = el; },
        key: colIndex,
        onContextMenu: (e) => { onContextMenu(e, data, rowIndex, (colIndex + freezeColumns)); },
        onMouseOver: ({target}) => {
          const el = target.closest('td');

          // to overcome bug when EditableText Dialog is created through Portal
          if (el) {
            tdOnMouseOver(colIndex + freezeColumns);
          }
        },
        onMouseLeave: () => tdOnMouseLeave(),
        onClick: this.handleTdClick,
        onMouseEnter: this.handleMouseEnter,
        title: hasTitle ? (typeof tdContent === 'string' ? tdContent : undefined) : undefined,
      };
    }

    const tdContentProps = {
      style: { height: `${tbodyRowHeight}px` },
    }

    return (
      <td {...tdProps}>
        {!isSkeleton ? (
          <>
            {field.hover &&
              <div {...tdContentProps} className="td-content _hover">
                <div className="td-content-inner">
                  {field.hover(data, field)}
                </div>
              </div>
            }
            {React.isValidElement(clickElement) &&
              <div {...tdContentProps} className="td-content _click">
                <div className="td-content-inner">
                  {React.cloneElement(
                    clickElement,
                    childrenProps,
                  )}
                </div>
              </div>
            }
            <TdInner tooltip={field.tooltip} isUnsorted={isUnsorted} tbodyRowHeight={tbodyRowHeight}>
              <div className="td-content-inner" ref={el => { this.tdElement = el; }}>
                {tdContent}
              </div>
            </TdInner>
          </>
        ) : (
          <TdInner tbodyRowHeight={tbodyRowHeight}>
            <div className="td-content-inner" style={{ width: '100%' }}>
              <div className="skeleton-block" style={{ width: '100%', height: `${17 / 16}rem` }} />
            </div>
          </TdInner>
        )}
      </td>
    );
  }
}

TdInner.propTypes = {
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isUnsorted: PropTypes.bool,
};

TableCell.propTypes = {
  rowIndex: PropTypes.number,
  data: PropTypes.any,
  onContextMenu: PropTypes.func,
  colIndex: PropTypes.number,
  freezeColumns: PropTypes.number,
  tdOnMouseOver: PropTypes.func,
  tdOnMouseLeave: PropTypes.func,
  field: PropTypes.object,
  isSkeleton: PropTypes.bool,
  tbodyRowHeight: PropTypes.number,
};
