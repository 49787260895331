import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';

export class WithErrorPlaceholderImage extends React.Component {
  state = {
    hasLoadingError: false,
  }

  onError = () => this.setState({ hasLoadingError: true })

  render() {
    if (this.state.hasLoadingError) {
      return <Icon name="NoData" />;
    }

    return (
      <img
        src={this.props.src}
        onError={this.onError}
      />
    );
  }
}

WithErrorPlaceholderImage.propTypes = {
  src: PropTypes.string,
};
