import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_text-field.scss';
import { Icon } from './Icon';
import { isEqual } from '../utils/compare';

export class TextFieldWithIcon extends Component {
  state = {
    focused: false,
    isFilled: !!this.props.value,
  }

  inputRef = null;

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual([
      'name',
      'placeholder',
      'type',
      'iconName',
      'value',
      'svgParams',
      'reset',
    ], this.props, nextProps) && isEqual([
      'focused',
      'isFilled',
    ], this.state, nextState));
  }

  componentDidMount() {
    this.props.inputRef && this.props.inputRef(this.inputRef);
  }

  checkDirty({ value }) {
    const { isFilled } = this.state;
    isFilled !== !!value && this.setState({ isFilled: !!value });
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    onChange(e);
    this.checkDirty(e.target);
  }

  handleFocus = (event) => {
    this.inputRef && this.inputRef.select();
    this.setState({ focused: true });
  };

  handleBlur = (event) => {
    this.setState({ focused: false });
  };

  render() {
    const {
      name,
      placeholder,
      type,
      iconName,
      onChange,
      value,
      svgParams,
      ...rest
    } = this.props;

    const {
      focused,
      isFilled,
    } = this.state;

    const reset = iconName === 'Search';

    const containerClassName = ['text-field-icon'];
    if (focused) {
      containerClassName.push('_focused');
    }
    if (reset) {
      containerClassName.push('_reset-icon');
    }
    if (iconName) {
      containerClassName.push('_icon');
    }

    const labelClassName = ['text-field-icon__label'];
    if (focused || isFilled) {
      labelClassName.push('_up');
    }
    if (focused) {
      labelClassName.push('_focused');
    }

    return (
      <div className={containerClassName.join(' ')}>
        {iconName &&
          <i className="text-field-icon__icon">
            <Icon name={iconName} {...svgParams} />
          </i>
        }
        <div className={labelClassName.join(' ')}>
          {placeholder}
        </div>
        <input
          type={type}
          name={name}
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className="text-field-icon__field"
          ref={el => { this.inputRef = el; }}
          {...rest}
        />
        {reset && value &&
          <button
            className="text-field-icon__reset"
            onClick={() => onChange({ target: { value: '' } })}
          >
            <Icon name="Close" />
          </button>
        }
      </div>
    );
  }
}

TextFieldWithIcon.defaultProps = {
  type: 'text',
  onChange: () => {
  },
  svgParams: {},
};

TextFieldWithIcon.propTypes = {
  iconName: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  svgParams: PropTypes.object,
  reset: PropTypes.bool,
  focused: PropTypes.bool,
};
