import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_card.scss';

const isFunction = (element) => {
  return typeof element === 'function';
};

export const CardHoveredContent = ({ children}) => {
  return (
    <div className="card__overlay">
      {children}
    </div>
  );
};

export const CardHoveredSubtitle = ({children}) => {
  return (
    <div className="card__subtitle_hovered">{children}</div>
  );
};

export const CardSubtitle = ({children}) => {
  return (
    <div className="card__subtitle">{children}</div>
  );
};

export class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
    };
  }

  onMouseOver = () => {
    this.setState({
      hovered: true,
    });
  };

  onMouseOut = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const {
      title,
      subtitle,
      children,
    } = this.props;

    const {
      state
    } = this;

    const {
      hovered,
    } = state;

    return (
      <div className={`card ${hovered ? 'card_hovered' : ''}`} onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut}>
        <header className="card__header">
          <div className="card__title">{title}</div>
          <div className="card__title card__title_subtitle">{isFunction(subtitle) ? subtitle(state) : subtitle}</div>
        </header>
        <main className="card__content">
          {isFunction(children) ? children(state) : children }
        </main>
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.func])
};
