import React from 'react';
import '../scss/_ripple.scss';

export class Ripple extends React.Component {
  constructor() {
    super();
    this.state = {
      animate: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    }
  }

  ripple = React.createRef();

  render() {
    return (
      <div className={`ripple ${this.state.animate ? 'is-reppling' : ''}`} ref={this.ripple} style={{
        top: this.state.top + 'px',
        left: this.state.left + 'px',
        width: this.state.width + 'px',
        height: this.state.height + 'px',
      }}/>
    );
  }

  reppling(cursorPos) {
    let ripple = this.ripple.current;
    let parent = ripple.parentElement;

    let buttonPos = parent.getBoundingClientRect();

    let buttonWidth = parent.offsetWidth;
    let buttonHeight = parent.offsetHeight;

    // Make a Square Ripple
    let rippleWidthShouldBe = Math.max(buttonHeight, buttonWidth);

    // Make Ripple Position to be center
    let centerize = rippleWidthShouldBe / 2;

    this.setState({
      animate: true,
      width: rippleWidthShouldBe,
      height: rippleWidthShouldBe,
      top: cursorPos.top - buttonPos.top - centerize,
      left: cursorPos.left - buttonPos.left - centerize,
    })
  }

  componentWillReceiveProps(nextProps) {
    let cursorPos = nextProps.cursorPos;

    // Prevent Component duplicates do ripple effect at the same time
    if (cursorPos.time !== this.props.cursorPos.time) {
      if (this.state.animate) {
        this.setState({ animate: false }, () => {
          this.reppling(cursorPos)
        })
      } else {
        this.reppling(cursorPos);
      }
    }
  }

}