import React from 'react';
import { sortableElement } from 'react-sortable-hoc';
import { Icon } from '../Icon';

export const SortableItem = sortableElement(({ item, className, moveCb, isActive, ...additionalProps }) => (
  <div
    className={className}
    {...additionalProps}
  >
    <div className="select__option-label">
      {item.reactElement || item.label}
    </div>
    {isActive &&
      <React.Fragment>
        <i className="select__option-icon _dnd"><Icon name="DragIndicator" /></i>
      </React.Fragment>
    }
  </div>
));
