import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_modal.scss';

const BODY_CLASS = 'modal__overflow';

export class Modal extends Component {
  overlayElement = React.createRef();

  componentDidMount(){
    document.body.classList.add(BODY_CLASS);
    document.addEventListener('keydown', this.handleKeyEvent)
  }
  componentWillUnmount() {
    document.body.classList.remove(BODY_CLASS);
    document.removeEventListener('keydown', this.handleKeyEvent);
  }

  handleClickOutside = (e) => {
    if (e.target === this.overlayElement.current) {
      this.onClickOutside(e);
    }
  };

  onClickOutside = (e) => {
    this.props.onClickOutside && this.props.onClickOutside(e);
  };

  handleKeyEvent = (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.onClickOutside(e);
    }
  };

  render() {
    const { open, children, modalProps } = this.props;

    if (!open) {
      return null;
    }

    return (
      <div className="modal" {...modalProps}>
        <div className="modal__overlay" ref={this.overlayElement} onClick={this.handleClickOutside}>
          {children}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  onClickOutside: PropTypes.func,
};
