import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-daterange-picker';
import originalMoment from 'moment';
import range from 'moment-range';
import Transition from 'react-transition-group/Transition';

import { ReactComponent as IconCalendar } from './datesPicker/date_range.svg';
import { ReactComponent as OneDateIconCalendar } from './datesPicker/one_date.svg';
import '../scss/_date-range-picker.scss';

import { withClickOutside } from '../hoc/clickOutside';
import { Button } from './Button';
import { DatesPickerWithTimePicker } from './datesPicker/DatesPickerWithTimePicker';
import { DatesPickerOnDoneWrapper } from './datesPicker/DatesPickerOnDoneWrapper';
import { momentOrDatePropCheck } from './datesPicker/utils';
import {Tooltip} from './Tooltip';

const moment = range.extendMoment(originalMoment);

const noop = () => {
  console.log('noop');
};

const DATE_FORMAT = 'MM/DD/YYYY';

export const DEFAULT_PRESELECTED_VALUES = (function () {
  const end = moment().endOf('day');
  return [{
    key: 'week',
    title: 'Week',
    start: moment().add(-1, 'week').startOf('day'),
    end,
  }, {
    key: 'month',
    title: 'Month',
    start: moment().add(-1, 'month').startOf('day'),
    end,
  }, {
    key: '3months',
    title: '3 Months',
    start: moment().add(-3, 'month').startOf('day'),
    end,
  }];
})();

const getMomentDate = (date) => {
  if (date instanceof moment) {
    return date;
  }
  return moment(date, DATE_FORMAT);
};

const DatesPickerWrapper = ({children, tooltipParams}) => {
  return tooltipParams ? (
    <Tooltip {...tooltipParams}>
      {children}
    </Tooltip>
  ) : children;
}

export class DatesPickerComponent extends Component {
  static defaultProps = {
    updateDateRange: noop,
    dateRange: {
      start: '',
      end: '',
    },
    preselected: false,
    insidePreselectors: false,
    singleDate: '',
    singleDateMode: false,
    labels: [],
    dateFormat: DATE_FORMAT,
    withTimePicker: false,
    updateByDone: true,
    label: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      preselectedRange: false, // props.dateRange && props.dateRange.range,
      preselectedRangeValues: this.getPreselectedList(),
      focusedElement: null,
      inputValue: null,
      afterSelection: false,
      isButtonDisabled: false,
    };
  }

  startInputRef = null;

  get withTimePicker() {
    const { singleDateMode, withTimePicker } = this.props;

    return singleDateMode && withTimePicker;
  }

  getPreselectedList() {
    const { preselected } = this.props;

    let preselectedRangeValues;
    if (preselected) {
      if (preselected === true) {
        preselectedRangeValues = DEFAULT_PRESELECTED_VALUES;
      } else if (preselected instanceof Array) {
        preselectedRangeValues = preselected;
      } else {
        throw Error('The preselected property must be a boolean or an Array types');
      }
    }
    return preselectedRangeValues;
  }

  componentDidMount() {
    if (!this.props.singleDateMode && this.props.preselected && this.props.dateRange) {
      this.checkPreselectedValues(this.props.dateRange, this.state.preselectedRangeValues);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let preselectedRangeValues;
    if (this.props.preselected !== prevProps.preselected) {
      preselectedRangeValues = this.getPreselectedList();
      this.setState(() => ({
        preselectedRangeValues
      }));
    }
    if (!this.props.singleDateMode && this.props.preselected && prevProps.dateRange !== this.props.dateRange) {
      this.checkPreselectedValues(this.props.dateRange, preselectedRangeValues || this.state.preselectedRangeValues);
    }
    if (this.startInputRef && this.state.isOpen && (prevState.isOpen !== this.state.isOpen)) {
      this.startInputRef.select();
    }
  }

  checkPreselectedValues(dateRange, preselectedRange) {
    const start = getMomentDate(dateRange.start);
    const end = getMomentDate(dateRange.end);

    for (let ii = 0; ii < preselectedRange.length; ii++) {
      const current = preselectedRange[ii];
      const rangeStart = getMomentDate(current.start);
      const rangeEnd = getMomentDate(current.end);

      if (start.isSame(rangeStart, 'day') && end.isSame(rangeEnd, 'day')) {
        this.setState({
          preselectedRange: current.key,
        });
        return;
      }
    }

    if (this.state.preselectedRange) {
      this.setState({
        preselectedRange: null,
      });
    }
  }

  onSelect = (value, states, preselectedRange) => {
    const { singleDateMode, updateDateRange } = this.props;
    if (!value) {
      return;
    }
    let { start, end } = value;
    if (end) {
      end = moment(end).endOf('day');
    }

    this.setState({
      value,
      states,
      preselectedRange,
      inputValue: null,
      afterSelection: true,
      isButtonDisabled: false,
    });
    singleDateMode
      ? updateDateRange(value, preselectedRange)
      : updateDateRange(start, end, preselectedRange);
  };

  revertAfterSelection = () => {
    this.setState({
      afterSelection: false,
    });
  };

  shouldComponentUpdate(newProps, newState) {
    if (this.state.isOpen !== newState.isOpen || this.state.focusedElement !== newState.focusedElement) {
      return true;
    }
    return !(newProps.dateRange === this.props.dateRange &&
      newState.preselectedRange === this.state.preselectedRange &&
      newState.preselectedRangeValues === this.state.preselectedRangeValues &&
      newProps.singleDate === this.props.singleDate &&
      newProps.preselected === this.props.preselected &&
      newProps.afterSelection === this.state.afterSelection
    );
  }

  onToggle = () => {
    if (this.props.disableNavigation) return;
    this.setState({ isOpen: !this.state.isOpen });
  };

  onOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  onClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onInputFocus = (e, field) => {
    const { target } = e;
    const value = target.value;
    !this.state.isOpen && target.blur();

    target.setSelectionRange(0, value.length);
    this.setState({
      focusedElement: field === 'singleDate' ? null : field,
    });
  };

  onInputBlur = (e, field) => {
    const { isOpen } = this.state;
    this.setState({
      focusedElement: null,
    });

    isOpen && this.onInputEntered(e, field);
  };

  onInputKeyEnter = (e, field) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.onInputEntered(e, field, true);
      e.target.blur();
      this.onClose();
    }
  };

  onInputEntered = (e, field, fromEnterKey) => {
    const { dateRange, singleDate, singleDateMode, dateFormat } = this.props;
    const { target } = e;
    const momentValue = originalMoment(target.value, dateFormat);
    const prevValue = singleDateMode ? singleDate : dateRange;

    // Checking if value is valid and could be selected
    if (!momentValue.isValid() || (this.pickerComponent && this.pickerComponent.isDateDisabled(momentValue) && !this.pickerComponent.isDateSelectable(momentValue))) {
      const value = singleDateMode ? prevValue : { ...prevValue };
      return this.onSelect(value);
    }

    let newValue;

    switch (field) {
      case 'start': {
        if (momentValue.isAfter(originalMoment(prevValue.end))) {
          newValue = prevValue.start;
        } else {
          newValue = momentValue.startOf('day').toDate();
        }
        break;
      }
      case 'end': {
        if (momentValue.isBefore(originalMoment(prevValue.start))) {
          newValue = prevValue.end;
        } else {
          newValue = momentValue.endOf('day').toDate();
        }
        break;
      }
      case 'singleDate': {
        newValue = this.props.withTimePicker
          ? momentValue.toDate()
          : momentValue.endOf('day').toDate();
        break;
      }
      default: {
        newValue = prevValue[field];
      }
    }

    field === 'singleDate'
      ? this.setState({ inputValue: newValue }, () => fromEnterKey && this.onSelect(newValue))
      : this.setState({
        inputValue: {
          ...prevValue,
          [field]: newValue,
        },
      }, () => fromEnterKey && this.onSelect({
        ...prevValue,
        [field]: newValue,
      }));
  };

  renderSelectionValue = () => {
    const {
      singleDate: singleDateProps,
      dateRange,
      singleDateMode,
      dateFormat,
      label,
      singleDatePlaceholder,
      tooltipParams,
      className = '',
    } = this.props;
    let { start, end } = dateRange || {};
    let singleDate = singleDateMode && singleDateProps;
    const { inputValue } = this.state;

    if (start) {
      start = moment(inputValue ? inputValue.start : start).format(dateFormat);
    }

    if (end) {
      end = moment(inputValue ? inputValue.end : end).format(dateFormat);
    }

    if (singleDate) {
      singleDate = moment(inputValue || singleDate).format(dateFormat);
    }

    return (
      <DatesPickerWrapper tooltipParams={tooltipParams}>
        <div className={`date-picker ${this.state.isOpen ? '_opened' : ''} ${className}`} onClick={this.onOpen}>
          <div className="date-picker__label">
            {label || `Date ${singleDateMode ? '' : 'Range'}`}
          </div>
          <i className={`icon date-picker__icon ${singleDateMode ? '_single' : ''}`}>
            {singleDateMode
              ? <OneDateIconCalendar />
              : <IconCalendar />
            }
          </i>
          <div className="date-picker__wrapper">
            <div className={`date-picker__item ${singleDateMode ? '_single' : ''}`}>
              <label className="date-picker__item-label" onClick={e => !this.state.isOpen && e.preventDefault()}>
                {/* <span>{labels[0] || 'Start date:'}</span> */}
                <input
                  ref={(el) => {
                    if (el) {
                      el.value = singleDateMode ? singleDate : start;
                      this.startInputRef = el;
                    }
                  }}
                  onFocus={(e) => this.onInputFocus(e, singleDateMode ? 'singleDate' : 'start')}
                  onBlur={(e) => this.onInputBlur(e, singleDateMode ? 'singleDate' : 'start')}
                  type="text"
                  size="5"
                  placeholder={singleDatePlaceholder}
                  className="form-control"
                  onKeyDown={(e) => this.onInputKeyEnter(e, singleDateMode ? 'singleDate' : 'start')}
                  tabIndex={0}
                />
              </label>
            </div>
            {!singleDateMode &&
              <React.Fragment>
                <div className="date-picker__item-divider"> – </div>
                <div className="date-picker__item">
                  <label className="date-picker__item-label">
                    {/* <span>{labels[1] || 'End date:'}</span> */}
                    <input
                      type="text"
                      size="5"
                      className="form-control"
                      ref={(el) => {
                        if (el) {
                          el.value = end;
                        }
                      }}
                      onFocus={(e) => this.onInputFocus(e, 'end')}
                      onBlur={(e) => this.onInputBlur(e, 'end')}
                      onKeyDown={(e) => this.onInputKeyEnter(e, 'end')}
                      tabIndex={0}
                    />
                  </label>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </DatesPickerWrapper>
    );
  };

  selectPreselectedRange = onSelectCallback => event => {
    const rangeID = event.target.getAttribute('data-range-id');
    if (!rangeID) return;
    const range = this.state.preselectedRangeValues.reduce((acc, item) => {
      acc[item.key] = item;
      return acc;
    }, {});

    const {
      start,
      end,
    } = range[rangeID];

    const r = moment.range(start, end);
    this.setState({ isButtonDisabled: false });

    if (onSelectCallback) {
      onSelectCallback(r, null, rangeID);
    } else {
      this.onSelect(moment.range(start, end), null, rangeID);
    }
  };

  renderPreselectedRange(onSelectCallback, selectedRangeId) {
    if (!this.props.preselected) {
      return null;
    }
    const {
      preselectedRangeValues,
    } = this.state;

    return (
      <div
        data-active-range={this.state.preselectedRange}
        className="date-range-picker__btns"
        onClick={this.selectPreselectedRange(onSelectCallback)}
      >
        {preselectedRangeValues.map((item, index) => {
          return (
            <button key={index}
              className={`date-range-picker__btn ${(selectedRangeId === undefined ? this.state.preselectedRange === item.key : selectedRangeId === item.key) ? '_active' : ''}`}
              data-range-id={item.key}
            >
              {item.title}
            </button>
          );
        })}
      </div>
    );
  }

  handleClickOutside = event => {
    if (this.state.isOpen) {
      event.stopPropagation();
    }
    this.onClose();
  };

  innerWrapperHandler = (el) => {
    if (el) {
      try {
        const elLeft = Number.parseFloat(window.getComputedStyle(el).left);
        const parentLeft = el.parentElement.getBoundingClientRect().left;
        const diff = Math.abs(parentLeft) - Math.abs(elLeft);

        if (diff < 0) {
          el.style.right = `${-(Math.abs(diff) + 10)}px`;
        }
      } catch (e) {
        el.style.right = 0;
      }
    }
  };

  render() {
    const { singleDate, singleDateMode, insidePreselectors } = this.props;
    let { datePickerProps } = this.props;
    const { isButtonDisabled, isOpen } = this.state;
    const { start, end } = this.props.dateRange || {};
    let value1 = null;

    if (start && end) {
      value1 = moment.range(moment(start).startOf('day'), moment(end));
    } else if (singleDateMode) {
      value1 = singleDate ? moment(singleDate) : null;
    }

    const animateYAxisClassName = this.props.datePickerProps.animationAxis === 'Y' ? '_animate-y-axis' : '';

    return (
      <div className={`date-range-picker ${isOpen ? '_opened' : ''} ${this.withTimePicker ? 'date-range-picker_with-time' : ''}`}>
        {!singleDateMode && !insidePreselectors &&
          this.renderPreselectedRange()
        }
        <div className="date-range-picker__wrapper">
          {this.renderSelectionValue()}

          <Transition in={this.state.isOpen} timeout={{ enter: 1, exit: 250 }} unmountOnExit>
            {(state) => {
              return (
                <DatesPickerOnDoneWrapper
                  value={value1}
                  inputValue={this.state.inputValue || value1}
                  resetInputValue={() => this.setState({ inputValue: null })}
                  onSelect={this.onSelect}
                  updateByDone={this.props.updateByDone}
                  preselectedRangeValues={this.getPreselectedList()}>
                  {(value, onSelect, preselectedRangeKey) => {
                    return (
                      <div
                        className={`date-range-picker__wrapper-inner _${state} ${animateYAxisClassName}`}
                        ref={this.innerWrapperHandler}
                      >
                        <DatesPickerWithTimePicker
                          toggleToTime={this.state.afterSelection}
                          clearAfterSelection={this.revertAfterSelection}
                          value={value}
                          withTimePicker={this.withTimePicker}
                          onChange={onSelect}
                        >
                          <DateRangePicker
                            ref={(comp) => {
                              this.pickerComponent = comp;
                            }}
                            value={value}
                            onSelect={v => {
                              onSelect(v);
                              this.setState({
                                afterSelection: true,
                                isButtonDisabled: false,
                              });
                            }}
                            focusedElement={this.state.focusedElement}
                            selectionType={singleDateMode ? 'single' : 'range'}
                            onSelectStart={() => this.setState({ isButtonDisabled: true })}
                            {...datePickerProps}
                          />
                        </DatesPickerWithTimePicker>
                        <div className="date-range-picker__controls">
                          {this.props.insidePreselectors && this.renderPreselectedRange(onSelect, preselectedRangeKey)}
                          <Button
                            className="btn-square _conflower-blue _xs date-range-picker__btn-done _filled"
                            onClick={(event) => {
                              this.onSelect(this.state.inputValue || value);
                              this.handleClickOutside(event);
                            }}
                            disabled={isButtonDisabled}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </DatesPickerOnDoneWrapper>
              );
            }}
          </Transition>
        </div>
      </div>
    );
  }
}

DatesPickerWrapper.propTypes = {
  tooltipParams: PropTypes.object,
};

const propTypes = {
  datePickerProps: PropTypes.object,
  dateRange: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.number, momentOrDatePropCheck]),
    end: PropTypes.oneOfType([PropTypes.number, momentOrDatePropCheck]),
  }),
  singleDate: PropTypes.oneOfType([PropTypes.number, momentOrDatePropCheck]),
  preselected: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(
    PropTypes.shape({
      start: momentOrDatePropCheck,
      end: momentOrDatePropCheck,
      title: PropTypes.string,
      key: PropTypes.string,
    }),
  )]),
  insidePreselectors: PropTypes.bool,
  updateDateRange: PropTypes.func,
  dateFormat: PropTypes.string,
  singleDateMode: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  withTimePicker: PropTypes.bool,
  updateByDone: PropTypes.bool,
  label: PropTypes.string,
  singleDatePlaceholder: PropTypes.string,
  tooltipParams: PropTypes.object,
  className: PropTypes.string,
};

DatesPickerComponent.propTypes = propTypes;

export const DatesPicker = withClickOutside(DatesPickerComponent);

DatesPicker.propTypes = propTypes;
