import * as moment from 'moment';

export function momentOrDatePropCheck(props, propName) {
  let val = props[propName];

  if (!val) {
    return null;
  } else if (moment.isMoment(val)) {
    return null;
  } else if (val instanceof Date) {
    return null;
  }
  return new Error(`'${propName}' must be a moment or Date`);
}
