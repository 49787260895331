import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../scss/_btn.scss';
import { Icon } from './Icon';
import { Ripple } from './Ripple';

const defaultClass = 'btn-square _persimmon';

export class Button extends Component {
  constructor() {
    super();
    this.state = {
      cursorPos: {},
    };
  }

  rippleOnMouseDown = (e) => {
    let cursorPos = {
      top: e.clientY,
      left: e.clientX,
      time: Date.now(),
    };

    this.setState({ cursorPos: cursorPos })
  };

  render() {
    const {
      children,
      className = defaultClass,
      size,
      ripple = true,
      ...rest
    } = this.props;

    return (
      <button
        className={`${className} ${size ? `_${size}` : ''}`}
        onMouseDown={this.rippleOnMouseDown}
        {...rest}
      >
        <span className="btn-content">
          {children}
        </span>
        {ripple &&
        <div className="ripple-parent_button">
          <Ripple cursorPos={this.state.cursorPos}/>
        </div>
        }
      </button>
    )
  }
}

export const ButtonCircle = ({ iconName, iconClass, outline, inverse, className, ...rest }) => {
  return <Button
    {...rest}
    className={`btn-circle ${outline ? '_outline' : ''} ${inverse ? '_inverse' : ''} ${className ? className : ''}`}
  >
    <Icon className={iconClass} name={iconName}/>
  </Button>
};

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg']),
  ripple: PropTypes.bool,
};
