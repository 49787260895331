import '../scss/_icon.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PDF } from './icon/pdf.svg';
import { ReactComponent as CSV } from './icon/csv.svg';
import { ReactComponent as XLS } from './icon/xls.svg';
import { ReactComponent as Delete } from './icon/delete.svg';
import { ReactComponent as Copy } from './icon/copy.svg';
import { ReactComponent as Spending } from './icon/spending.svg';
import { ReactComponent as Reorder } from './icon/reorder.svg';
import { ReactComponent as Checkbox } from './icon/check-box-24px.svg';
import { ReactComponent as CheckboxBlank } from './icon/check-box-blank-24px.svg';
import { ReactComponent as DateRange } from './icon/date_range.svg';
import { ReactComponent as DragIndicator } from './icon/drag-indicator.svg';
import { ReactComponent as Apps } from './icon/apps.svg';
import { ReactComponent as Notification } from './icon/notifications.svg';
import { ReactComponent as Created } from './icon/created.svg';
import { ReactComponent as Print } from './icon/print.svg';
import { ReactComponent as Refresh } from './icon/refresh.svg';
import { ReactComponent as Updated } from './icon/updated.svg';
import { ReactComponent as Impressions } from './icon/impressions.svg';
import { ReactComponent as Close } from './icon/close.svg';
import { ReactComponent as Add } from './icon/add.svg';
import { ReactComponent as Left } from './icon/left.svg';
import { ReactComponent as Collapse } from './icon/collapse.svg';
import { ReactComponent as Right } from './icon/right.svg';
import { ReactComponent as Expand } from './icon/expand.svg';
import { ReactComponent as UnfoldMore } from './icon/unfold_more.svg';
import { ReactComponent as ArrowLeft } from './icon/arrow_left.svg';
import { ReactComponent as ArrowUp } from './icon/arrow_up.svg';
import { ReactComponent as ArrowRight } from './icon/arrow_right.svg';
import { ReactComponent as ArrowDown } from './icon/arrow_down.svg';
import { ReactComponent as DropDown } from './icon/drop_down.svg';
import { ReactComponent as DropUp } from './icon/drop_up.svg';
import { ReactComponent as Report } from './icon/report.svg';
import { ReactComponent as NoData } from './icon/no_data.svg';
import { ReactComponent as Search } from './icon/search.svg';
import { ReactComponent as Marketplace } from './icon/marketplace.svg';
import { ReactComponent as Edit } from './icon/edit.svg';
import { ReactComponent as Menu } from './icon/menu.svg';
import { ReactComponent as Filter } from './icon/filter.svg';
import { ReactComponent as More } from './icon/more.svg';
import { ReactComponent as Agenda } from './icon/agenda.svg';
import { ReactComponent as Done } from './icon/done.svg';
import { ReactComponent as Sort } from './icon/sort.svg';
import { ReactComponent as Star } from './icon/star.svg';
import { ReactComponent as Tap } from './icon/tap.svg';
import { ReactComponent as Info } from './icon/info.svg';
import { ReactComponent as List } from './icon/list.svg';
import { ReactComponent as Table } from './icon/table.svg';
import { ReactComponent as OneDate } from './icon/one_date.svg';
import { ReactComponent as AddEmail } from './icon/add_email.svg';
import { ReactComponent as Question } from './icon/question.svg';
import { ReactComponent as Upload } from './icon/upload.svg';

// creative icons
import { ReactComponent as BannerSM } from './icon/banner_sm.svg';
import { ReactComponent as HtmlSM } from './icon/html_sm.svg';
import { ReactComponent as gotvSM } from './icon/gotv_sm.svg';
import { ReactComponent as scrollSM } from './icon/scroll_sm.svg';
import { ReactComponent as VideoSM } from './icon/video_sm.svg';
import { ReactComponent as VoiceSM } from './icon/voice_sm.svg';
import { ReactComponent as BannerXS } from './icon/banner_xs.svg';
import { ReactComponent as HtmlXS } from './icon/html_xs.svg';
import { ReactComponent as gotvXS } from './icon/gotv_xs.svg';
import { ReactComponent as scrollXS } from './icon/scroll_xs.svg';
import { ReactComponent as VideoXS } from './icon/video_xs.svg';
import { ReactComponent as VoiceXS } from './icon/voice_xs.svg';
import { ReactComponent as NativeAdXs } from './icon/native-ad_xs.svg';
import { ReactComponent as Banner } from './icon/banner.svg';
import { ReactComponent as Html } from './icon/html.svg';
import { ReactComponent as Gotv } from './icon/gotv.svg';
import { ReactComponent as Scroll } from './icon/scroll.svg';
import { ReactComponent as Video } from './icon/video.svg';
import { ReactComponent as Voice } from './icon/voice.svg';
import { ReactComponent as Globe } from './icon/globe.svg';

// device icons
import { ReactComponent as Phone } from './icon/phone.svg';
import { ReactComponent as Tablet } from './icon/tablet.svg';
import { ReactComponent as Desktop } from './icon/desktop.svg';
import { ReactComponent as SmartTV } from './icon/smart_tv.svg';
import { ReactComponent as OtherEmbedded } from './icon/other_embedded.svg';

// audience icons
import { ReactComponent as CustomAudience } from './icon/audience_icon_1.svg';
import { ReactComponent as CampaignAudience } from './icon/audience_icon_2.svg';
import { ReactComponent as SegmentedAudience } from './icon/audience_icon_3.svg';
import { ReactComponent as TaggedAudience } from './icon/audience_icon_4.svg';
import { ReactComponent as GeofarmedAudience } from './icon/audience_icon_5.svg';
import { ReactComponent as Advanced } from './icon/audience_icon_6.svg';
import { ReactComponent as Template } from './icon/template.svg';

export const creativeIcons = {
  xs: {
    BannerSM,
    HtmlSM,
    gotvSM,
    scrollSM,
    VideoSM,
    VoiceSM,
  },
  sm: {
    BannerXS,
    HtmlXS,
    gotvXS,
    scrollXS,
    VideoXS,
    VoiceXS,
    NativeAdXs,
  },
  default: {
    Banner,
    Html,
    Gotv,
    Scroll,
    Video,
    Voice,
  },
};

export const deviceIcons = {
  Phone,
  Tablet,
  Desktop,
  SmartTV,
  OtherEmbedded,
};

export const audienceIcons = {
  CustomAudience,
  CampaignAudience,
  SegmentedAudience,
  TaggedAudience,
  GeofarmedAudience,
  Advanced,
  Template,
};

export const commonIcons = {
  PDF,
  CSV,
  XLS,
  Created,
  Updated,
  Delete,
  Copy,
  Spending,
  Reorder,
  Checkbox,
  CheckboxBlank,
  DateRange,
  OneDate,
  DragIndicator,
  Apps,
  Notification,
  Print,
  Refresh,
  Impressions,
  Close,
  Add,
  Left,
  Collapse,
  Right,
  Expand,
  UnfoldMore,
  ArrowLeft,
  ArrowUp,
  ArrowRight,
  ArrowDown,
  DropDown,
  DropUp,
  Report,
  NoData,
  Search,
  Marketplace,
  Edit,
  Menu,
  Filter,
  More,
  Agenda,
  Done,
  Sort,
  Star,
  Tap,
  Info,
  List,
  Table,
  AddEmail,
  Question,
  Globe,
  Upload,
};

export const mapIcons = {
  ...commonIcons,
  ...creativeIcons.default,
  ...creativeIcons.sm,
  ...creativeIcons.xs,
  ...deviceIcons,
  ...audienceIcons,
};

export class Icon extends Component {
  render() {
    const { name, className, ...rest } = this.props;
    const Wrapper = mapIcons[name];
    return <Wrapper className={`svg-icon ${className || ''}`} {...rest} />;
  }
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(mapIcons)).isRequired,
};
